<template>
  <div class="p-2">
    <b-row>
      <b-col cols="12">
        <AgencyTopWidget :show="show" :data="agency" @show="$emit('show', $event)" />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="4">
        <AgencyWidget :show="show" :data="agencyData[0]" color="red" icon="fad fa-user">
          <template v-slot:body>
            <div class="text-white">
              <router-link class="text-white h5" v-if="agencyData[0].route" :to="agencyData[0].route">
                {{ agencyData[0].title }}
              </router-link>
              <span v-else>{{ agencyData[0].title }}</span>
              <h4>{{ agencyData[0].value }} Adet</h4>
            </div>
          </template>
          <template v-slot:actions>
            <b-button-group>
              <b-button variant="secondary" :to="agencyData[0].route">
                <i class="fad fa-list"></i>
              </b-button>
            </b-button-group>
          </template>
        </AgencyWidget>
      </b-col>
      <b-col cols="12" md="4">
        <AgencyWidget :show="show" :data="agencyData[1]" color="blue" icon="fad fa-user">
          <template v-slot:body>
            <div class="text-white">
              <router-link class="text-white h5" v-if="agencyData[1].route" :to="agencyData[1].route"
                >{{ agencyData[1].title }}
              </router-link>
              <span v-else>{{ agencyData[1].title }}</span>
              <h4 :class="agencyData[1].value == 1 ? 'text-white' : 'text-danger'">
                {{ agencyData[1].value == 1 ? 'Tanımlanmış' : 'Tanımlanmamış' }}
              </h4>
            </div>
          </template>
          <template v-slot:actions>
            <b-button-group>
              <b-button variant="secondary" :to="agencyData[1].route">
                <i class="fad fa-cogs"></i>
              </b-button>
            </b-button-group>
          </template>
        </AgencyWidget>
      </b-col>
      <b-col cols="12" md="4">
        <AgencyWidget :show="show" :data="agencyData[2]" color="green" icon="fad fa-user">
          <template v-slot:body>
            <div class="text-white">
              <router-link class="text-white h5" v-if="agencyData[2].route" :to="agencyData[2].route">
                {{ agencyData[2].title }}
              </router-link>
              <span v-else>{{ agencyData[2].title }}</span>
              <h4 :class="agencyData[2].value.kalan > 0 ? 'text-white' : 'text-danger'">
                {{ agencyData[2].value.kalan | cur }} {{ kurTurleri.varsayilan }}
                <p>{{ agencyData[2].value.kalan > 0 ? 'ALACAKLI' : 'BORÇLU' }}</p>
              </h4>
            </div>
          </template>
          <template v-slot:actions>
            <b-button-group>
              <b-button variant="secondary" :to="agencyData[2].route">
                <i class="fad fa-list"></i>
              </b-button>
            </b-button-group>
          </template>
        </AgencyWidget>
      </b-col>
      <b-col cols="12" md="4">
        <AgencyWidget :show="show" :data="agencyData[3]" color="purple" icon="fad fa-user">
          <template v-slot:body>
            <div class="text-white">
              <router-link class="text-white h5" v-if="agencyData[3].route" :to="agencyData[3].route">
                {{ agencyData[3].title }}
              </router-link>
              <span v-else>{{ agencyData[3].title }}</span>
              <h5>Aktif : {{ agencyData[3].value.aktif }} Adet</h5>
              <h5>Pasif : {{ agencyData[3].value.pasif }} Adet</h5>
            </div>
          </template>
          <template v-slot:actions>
            <b-button-group>
              <b-button variant="secondary" :to="agencyData[3].route">
                <i class="fad fa-list"></i>
              </b-button>
            </b-button-group>
          </template>
        </AgencyWidget>
      </b-col>
      <b-col cols="12" md="4" v-if="!agency.ac_k_no">
        <AgencyWidget :show="show" :data="agencyData[4]" color="orange" icon="fad fa-user">
          <template v-slot:body>
            <div class="text-white">
              <router-link class="text-white h5" v-if="agencyData[4].route" :to="agencyData[4].route">
                {{ agencyData[4].title }}
              </router-link>
              <span v-else>{{ agencyData[4].title }}</span>
              <h5>Aktif : {{ agencyData[4].value.aktif }} Adet</h5>
              <h5>Pasif : {{ agencyData[4].value.pasif }} Adet</h5>
            </div>
          </template>
          <template v-slot:actions>
            <b-button-group>
              <b-button variant="secondary" :to="agencyData[4].route">
                <i class="fad fa-list"></i>
              </b-button>
            </b-button-group>
          </template>
        </AgencyWidget>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AgencyTopWidget from '@/components/widget/AgencyTopWidget.vue';
import AgencyWidget from '@/components/widget/AgencyWidget.vue';
import { useRouter } from '@/libs/utils';
import store from '@/store';
import { ref, computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
  components: { AgencyTopWidget, AgencyWidget },
  props: { show: [Boolean] },
  setup(props, context) {
    const expo = {};
    const { route } = useRouter();
    expo.kurTurleri = computed(() => store.getters.getKurAyarlari);
    expo.agency = ref({});

    expo.agencyData = ref([
      {
        id: 0,
        route: `/acente/detail/${route.value.params.k_no}/rezervasyonlar`,
        title: 'Rezervasyonlar',
        value: 0,
        icon: 'fad fa-box-full',
        color: 'red',
      },
      {
        id: 1,
        route: `/acente/detail/${route.value.params.k_no}/komisyonlar`,
        title: 'Komisyon Oranları',
        value: 0,
        icon: 'fad fa-ticket',
        color: 'green',
      },
      {
        id: 2,
        route: `/acente/detail/${route.value.params.k_no}/finans`,
        title: 'Finansal Durumu',
        value: {
          beklemede: 0,
          iptal: 0,
          tamamlandi: 0,
          odeme: 0,
          kalan: 0,
        },
        icon: 'fad fa-file-chart-line',
        color: 'purple',
      },
      {
        id: 3,
        route: `/acente/detail/${route.value.params.k_no}/destek`,
        title: 'Destek Talepleri',
        value: {
          aktif: 0,
          pasif: 0,
        },
        icon: 'fad fa-box-full',
        color: 'green',
      },
      {
        id: 4,
        route: `/acente/detail/${route.value.params.k_no}/alt-acente`,
        title: 'Alt Acenteler',
        value: {
          aktif: 0,
          pasif: 0,
        },
        icon: 'fad fa-people-carry',
        color: 'orange',
        type: 'number',
      },
      {
        id: 5,
        route: `/acente/detail/${route.value.params.k_no}`,
        title: 'Kullanıcı Hesapları',
        value: 0,
        icon: 'fad fa-users',
        color: 'blue',
      },
    ]);

    const handlerFetchAgencyData = () => {
      context.emit('show', true);
      const k_no = route.value.params.k_no;
      if (k_no) {
        store.dispatch('acenteTumDatalariGetir', { k_no }).then((res) => {
          if (res.data.success) {
            const data = res.data;
            expo.agency.value = data.acente;
            expo.agencyData.value[0].value = data.rezervasyonlar;
            expo.agencyData.value[1].value = data.komisyonlar;
            expo.agencyData.value[2].value = data.hareketler;
            expo.agencyData.value[3].value = data.destek;
            expo.agencyData.value[4].value = data.alt_acente;
            context.emit('title', expo.agency.value.firma_adi);
            context.emit('show', false);
          }
        });
      }
    };

    handlerFetchAgencyData();

    return { ...expo };
  },
});
</script>

<style scoped></style>
