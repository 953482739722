<template>
  <div>
    <vue-good-table
      mode="remote"
      :columns="columns"
      :rows="rows"
      :line-numbers="true"
      :pagination-options="{
        enabled: true,
        perPage: 10,
        position: 'bottom',
        perPageDropdown: [5, 10, 20, 30, 50, 70, 100],
        dropdownAllowAll: true,
        firstLabel: 'İlk Sayfa',
        lastLabel: 'Son Sayfa',
        nextLabel: 'İleri',
        prevLabel: 'Geri',
        rowsPerPageLabel: 'Sayfa Başı Satır Sayısı',
        ofLabel: 've',
        pageLabel: 'Sayfa', // for 'pages' mode
        allLabel: 'Tümü',
      }"
      :search-options="{
        enabled: true,
        trigger: 'enter',
        placeholder: 'Tur Ara',
      }"
      @on-search="onSearchFilter"
      @on-page-change="onPageChange"
      @on-per-page-change="onPerPageChange"
      :isLoading="isLoading"
      :totalRows="count"
      :sort-options="{
        enabled: false,
      }"
      styleClass="vgt-table striped bordered"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'firma_adi'">
          <router-link
            :to="{
              name: 'acente-detail',
              params: { k_no: props.row.k_no },
            }"
          >
            <span class="d-block text-dark font-weight-bold">{{ props.row.firma_adi }}</span>
          </router-link>
        </span>
        <span v-else-if="props.column.field === 'uye_grubu'">
          <span class="rounded-0 bg-info text-light p-1 d-block text-center">
            {{ props.row.uye_grup }}
          </span>
        </span>
        <span v-else-if="props.column.field === 'iletisim'">
          <span class="d-block">{{ props.row.telefon }}</span>
          <span class="d-block">{{ props.row.e_mail }}</span>
        </span>
        <span v-else-if="props.column.field === 'bakiye'">
          <span
            class="rounded-0 text-light p-1 d-block text-center"
            :class="props.row.ac_bakiye.kalan > 0 ? 'bg-success' : 'bg-danger'"
            v-if="props.row.ac_bakiye"
          >
            {{ props.row.ac_bakiye.kalan | cur }} {{ props.row.sabit_kur }}
          </span>
        </span>
        <span v-else-if="props.column.field === 'statu'">
          <b-form-checkbox
            class="custom-control-info"
            v-model="props.row.statu"
            switch
            @change="handlerStatu(props.row.k_no, $event)"
            size="lg"
          />
        </span>
        <span v-else-if="props.column.field === 'action'" class="float-right px-1">
          <b-button-group size="sm" class="pb-25">
            <b-button
              variant="info"
              class="rounded-0"
              :to="{
                name: 'acente-detail',
                params: { k_no: props.row.k_no },
              }"
              v-b-tooltip.hover.top="'Acente Bilgileri'"
            >
              <i class="fad fa-eye" />
            </b-button>
            <b-button
              class="rounded-0"
              variant="warning"
              v-b-tooltip.hover.top="'Acente Güncelle'"
              :to="{
                name: 'acente-guncelle',
                params: { k_no: props.row.k_no },
              }"
            >
              <i class="fad fa-edit" />
            </b-button>
            <b-button variant="danger" class="rounded-0" v-b-tooltip.hover.top="'Acente Sil'" @click="handlerRemove(props.row.k_no)">
              <i class="fad fa-trash" />
            </b-button>
          </b-button-group>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import store from '@/store';
import Swal from 'sweetalert2';
import vSelect from 'vue-select';
import { VueGoodTable } from 'vue-good-table';
import { useToast } from 'vue-toastification/composition';
import { useRouter } from '@/libs/utils';
import { computed, defineComponent, onMounted, ref, watch } from '@vue/composition-api';
import Pagination from '@/views/rezervasyonlar/component/listele/Pagination.vue';
export default defineComponent({
  components: {
    vSelect,
    VueGoodTable,
    Pagination,
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();
    const { route, router } = useRouter();
    expo.columns = ref([
      {
        label: 'Acente Adı ( Ünvan )',
        field: 'firma_adi',
      },
      {
        label: 'İletişim',
        field: 'iletisim',
        width: '17%',
        thClass: 'text-right',
        thClass: 'text-right',
      },

      {
        label: 'Bakiye',
        field: 'bakiye',
        width: '10%',
        thClass: 'text-right',
        thClass: 'text-right',
      },
      {
        label: 'Statu',
        field: 'statu',
        width: '5%',
        thClass: 'text-right',
        tdClass: 'text-right',
      },
      {
        label: 'İşlemler',
        field: 'action',
        width: '10%',
        thClass: 'text-right',
        tdClass: 'text-right',
      },
    ]);
    expo.rows = ref([]);
    expo.count = ref(0);

    expo.isLoading = ref(false);
    const serverParams = ref({
      k_no: null,
      ac_k_no: null,
      page: 1,
      page_size: 5,
    });

    expo.handlerFetchAllData = async () => {
      expo.isLoading.value = true;
      expo.rows.value = [];
      serverParams.value.ac_k_no = route.value.params.k_no;
      await store.dispatch('acenteListele', serverParams.value).then((res) => {
        if (res.data.success) {
          expo.count.value = res.data.count;
          expo.rows.value = res.data.data;
        }
        expo.isLoading.value = false;
      });
    };

    expo.handlerFetchAllData();

    expo.onSearchFilter = (event) => {
      const { searchTerm } = event;
      serverParams.value.acente_adi = searchTerm;
      expo.handlerFetchAllData();
    };

    expo.onPageChange = (event) => {
      serverParams.value.page = event.currentPage;
      expo.handlerFetchAllData();
    };

    expo.onPerPageChange = (event) => {
      serverParams.value.page_size = event.currentPerPage;
      expo.handlerFetchAllData();
    };

    expo.handlerRemove = (k_no) => {
      Swal.fire({
        title: 'Uyarı',
        text: 'Tüm Veriler Silinecektir. Eminmisiniz ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          store.dispatch('acenteSil', k_no).then((res) => {
            if (res.data.success === true) {
              toast.error('Silme Başarılı', { position: 'bottom-left' });
            }
          });
        }
      });
    };

    return { ...expo };
  },
});
</script>

<style scoped></style>
