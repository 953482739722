<template>
  <div class="p-2 d-flex justify-content-between flex-wrap">
    <div class="d-flex align-items-center my-3">
      <v-select
        v-model="pageSize"
        :options="perPageOptions"
        :clearable="false"
        style="width: 200px"
        class="invoice-filter-select"
        @input="(value) => $emit('handlerPageSizeChange', value)"
      />
    </div>
    <div>
      <b-pagination
        :value="page"
        :total-rows="count"
        :per-page="pageSize"
        first-number
        last-number
        align="right"
        prev-class="prev-item"
        next-class="next-item"
        class="my-3"
        @input="(value) => $emit('handlerPageChange', value)"
      >
        <template #prev-text>
          <i class="fad fa-chevron-left" />
        </template>
        <template #next-text>
          <i class="fad fa-chevron-right" />
        </template>
      </b-pagination>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import { defineComponent, ref, computed, toRefs } from '@vue/composition-api';
import { useRouter } from '@/libs/utils';

export default defineComponent({
  components: {
    vSelect,
  },
  props: {
    count: [Number, String],
  },
  setup(props, context) {
    const expo = {};
    const { route } = useRouter();
    expo.perPageOptions = ref([10, 20, 40, 60, 80, 100]);

    expo.page = ref(route.value.query.page ? route.value.query.page : 1);
    expo.pageSize = ref(route.value.query.page_size ? route.value.query.page_size : 20);

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
