<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm" class="p-2" autocomplete="off">
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group label="Acente">
            <v-select
              v-model="form.ac_k_no"
              :options="acenteler"
              :reduce="(role) => role.k_no"
              label="firma_adi"
              :clearable="false"
              class="invoice-filter-select d-block select-size-lg"
              aria-describedby="input-1-live-feedback"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <validation-provider name="Yetki Adı" :rules="{ required: true }" v-slot="validationContext">
            <b-form-group label="Yetki Adı">
              <b-form-input
                size="lg"
                class="rounded-0"
                ref="role_adi"
                v-model="form.role_adi"
                :state="getValidationState(validationContext)"
                aria-describedby="input-1-live-feedback"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-table striped hover outlined responsive :head-variant="'light'" class="mb-0" :fields="fields" :items="form.authority">
            <template #cell(title)="data">
              {{ data.value }}
            </template>
            <template #cell()="data">
              <b-form-checkbox :checked="data.value" @change="handlerCheck(data)" switch size="lg" />
            </template>
          </b-table>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="6">
          <div class="d-flex justify-content-between align-items-center py-1">
            <label class="font-weight-bold h6 mt-2">Kullanım Durumu</label>
            <b-form-checkbox :checked="form.statu" v-model="form.statu" switch size="lg" />
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="6" lg="6">
          <div class="d-flex">
            <b-button squared block type="submit" size="lg" variant="primary" class="mr-2">
              <i class="fad fa-save pr-2"></i>
              <span class="align-middle">{{ form.k_no == null ? 'EKLE' : 'GÜNCELLE' }}</span>
            </b-button>
            <b-button squared size="lg" variant="danger">
              <i class="fad fa-trash"></i>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import vSelect from 'vue-select';
import { defineComponent, ref, computed, toRefs, watch } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
import store from '@/store';
import { useRouter } from '@/libs/utils';
export default defineComponent({
  components: {
    vSelect,
  },
  props: {},
  setup(props, context) {
    const expo = {};
    const toast = useToast();
    const { route } = useRouter();
    expo.acenteler = ref([{ k_no: null, firma_adi: 'Master Yetki' }]);

    expo.fields = ref(['title', 'listeleme', 'ekleme', 'duzenleme', 'silme']);

    expo.form = ref({
      role_adi: null,
      ac_k_no: null,
      statu: true,
      authority: [
        {
          title: 'Rezervasyon İşlemleri',
          modul: 'rezervasyon-islemleri',
          listeleme: true,
          duzenleme: true,
          ekleme: true,
          silme: true,
        },
        {
          title: 'Ürün İşlemleri',
          modul: 'urun-islemleri',
          listeleme: true,
          duzenleme: true,
          ekleme: true,
          silme: true,
        },
        {
          title: 'Transfer İşlemleri',
          modul: 'transfer-islemleri',
          listeleme: true,
          duzenleme: true,
          ekleme: true,
          silme: true,
        },
        {
          title: 'Bilet İşlemleri',
          modul: 'bilet-islemleri',
          listeleme: true,
          duzenleme: true,
          ekleme: true,
          silme: true,
        },
        {
          title: 'Finans İşlemleri',
          modul: 'finans-islemleri',
          listeleme: true,
          duzenleme: true,
          ekleme: true,
          silme: true,
        },
        {
          title: 'Acente İşlemleri',
          modul: 'acente-islemleri',
          listeleme: true,
          duzenleme: true,
          ekleme: true,
          silme: true,
        },
        {
          title: 'Üye İşlemleri',
          modul: 'uye-islemleri',
          listeleme: true,
          duzenleme: true,
          ekleme: true,
          silme: true,
        },
        {
          title: 'Raporlar',
          modul: 'raporlar',
          listeleme: true,
          duzenleme: true,
          ekleme: true,
          silme: true,
        },
        {
          title: 'Kullanicilar',
          modul: 'kullanicilar',
          listeleme: true,
          duzenleme: true,
          ekleme: true,
          silme: true,
        },
        {
          title: 'Ayarlar',
          modul: 'ayarlar',
          listeleme: true,
          duzenleme: true,
          ekleme: true,
          silme: true,
        },
      ],
    });

    const handlerFetchAllData = async () => {
      const k_no = route.value.params.k_no;

      context.emit('title', ' Acente Yetki Ekle');

      await store.dispatch('acenteListele', { statu: true }).then((res) => {
        if (res.data.success) {
          const acenteler = res.data.data;
          acenteler.forEach((el) => {
            expo.acenteler.value.push({
              k_no: el.k_no,
              firma_adi: el.firma_adi,
            });
          });
        }
      });

      if (k_no) {
        context.emit('show', true);
        store
          .dispatch('acenteKullaniciYetkiFindOne', { k_no })
          .then((res) => {
            if (res.data.success) {
              expo.form.value = res.data.data;

              context.emit('title', expo.form.value.yetki_adi + ' Acente Yetki Güncelle');
              context.emit('show', false);
            }
          })
          .catch((err) => {
            console.log(err);
            context.emit('show', false);
          });
      }
    };

    handlerFetchAllData();

    expo.handlerCheck = (data) => {
      const field = data.field;
      const index = data.index;
      expo.form.value.authority[index][field.key] = !expo.form.value.authority[index][field.key];
    };

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      context.emit('show', true);

      const baseUrl = expo.form.value.k_no ? 'acenteKullaniciYetkiGuncelle' : 'acenteKullaniciYetkiEkle';

      store.dispatch(baseUrl, expo.form.value).then((res) => {
        if (res.data.success === true) {
          if (expo.form.value.k_no) {
            toast.success('Güncelleme Başarılı', { position: 'bottom-left' });
          } else {
            toast.success('Ekleme Başarılı', { position: 'bottom-left' });
          }
          context.emit('show', false);
        }
      });
    };

    return { ...expo };
  },
});
</script>

<style lang="scss"></style>
