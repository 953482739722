import { render, staticRenderFns } from "./KomisyonUrun.vue?vue&type=template&id=aa1f5230&scoped=true&"
import script from "./KomisyonUrun.vue?vue&type=script&lang=js&"
export * from "./KomisyonUrun.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa1f5230",
  null
  
)

export default component.exports