var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{staticClass:"rounded-0",attrs:{"visible":_vm.openModal,"size":"lg","centered":"","title":"Alış Bölgesi Ekle"},on:{"change":function (val) { return _vm.$emit('closeModal', false); }},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100"},[_c('b-button',{staticClass:"float-right",attrs:{"squared":"","variant":"primary","type":"submit"},on:{"click":_vm.onSubmit}},[_c('i',{staticClass:"fad fa-save"}),_vm._v(" Seçili Satırları Aktar ")])],1)]},proxy:true}])},[_c('b-card-text',[(!_vm.urunler)?_c('b-alert',{staticClass:"text-center",attrs:{"show":"","variant":"warning"}},[_c('p',[_vm._v("Gösterilecek veri yok !")])]):_vm._e(),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.urunler,"line-numbers":false,"pagination-options":{
        enabled: false,
        perPage: 100,
        mode: 'pages',
      },"select-options":{
        enabled: true,
        selectOnCheckboxOnly: true,
        selectionText: 'Satır Secildi.',
        clearSelectionText: 'Temizle',
      },"search-options":{ enabled: true },"styleClass":"vgt-table striped bordered"},on:{"on-selected-rows-change":_vm.selectionChanged},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'baslik')?_c('span',[_c('span',{staticClass:"font-weight-bold d-block text-dark"},[_vm._v(" "+_vm._s(props.row.baslik)+" ")])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"selected-row-actions"},slot:"selected-row-actions"},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Toplu İşlem'),expression:"'Toplu İşlem'",modifiers:{"hover":true}}],attrs:{"squared":"","variant":"primary"},on:{"click":_vm.onSubmit}},[_c('i',{staticClass:"fad fa-save"}),_vm._v(" Seçili Satırları Aktar ")])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }