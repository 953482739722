<template>
  <div>Destek Hattı</div>
</template>

<script>
import { ref, computed, defineComponent } from '@vue/composition-api';
export default defineComponent({
  setup() {
    const expo = {};

    return { ...expo };
  },
});
</script>

<style scoped></style>
