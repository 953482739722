<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm" class="p-2" autocomplete="off">
      <b-row>
        <b-col cols="12" md="6">
          <validation-provider name="Acente Adı" :rules="{ required: true }" v-slot="validationContext">
            <b-form-group label="Acente Adı" :class="{ 'is-invalid': !!validationContext.errors[0] }">
              <treeselect
                :options="acenteler"
                v-model="form.ac_k_no"
                :normalizer="acenteNormalizer"
                :sort-value-by="sortValueBy"
                :default-expand-level="1"
                noChildrenText="Alt Öge Yok."
                placeholder="Lütfen Acente Seçiniz"
                :state="getValidationState(validationContext)"
                aria-describedby="input-1-live-feedback"
                :class="{ 'is-invalid border-danger': !!validationContext.errors[0] }"
                class="rounded-0"
                :max-height="200"
                @input="handlerAcenteChange($event)"
              />
              <b-form-invalid-feedback id="input-1-live-feedback">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12" md="6">
          <validation-provider name="Ad Soyad" :rules="{ required: true }" v-slot="validationContext">
            <b-form-group label="Ad Soyad">
              <b-form-input
                size="lg"
                class="rounded-0"
                ref="ad_soyad"
                v-model="form.ad_soyad"
                :state="getValidationState(validationContext)"
                aria-describedby="input-1-live-feedback"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <validation-provider name="Kullanıcı Adı" :rules="{ required: true }" v-slot="validationContext">
            <b-form-group label="Kullanıcı Adı">
              <b-form-input
                size="lg"
                class="rounded-0"
                ref="email"
                v-model="form.kullanici_adi"
                :state="getValidationState(validationContext)"
                aria-describedby="input-1-live-feedback"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12" md="6">
          <validation-provider name="Şifre" :rules="userSifreControl" v-slot="validationContext">
            <b-form-group label="Şifre">
              <b-input-group>
                <b-form-input
                  size="lg"
                  class="rounded-0"
                  ref="sifre"
                  v-model="form.sifre"
                  :type="passwordFieldType"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-1-live-feedback"
                />

                <b-input-group-prepend is-text>
                  <i :class="`fad fa-${passwordToggleIcon}`" @click="togglePasswordVisibility" />
                </b-input-group-prepend>
              </b-input-group>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group label="Telefon">
            <b-form-input
              size="lg"
              v-mask="'+## ### ### ## ##'"
              class="rounded-0"
              id="telefon"
              name="telefon"
              ref="telefon"
              v-model="form.telefon"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="E Posta">
            <b-form-input
              size="lg"
              class="rounded-0"
              ref="email"
              placeholder="__@mail.com"
              v-model="form.e_mail"
              aria-describedby="input-1-live-feedback"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <validation-provider name="Role" :rules="{ required: true }" v-slot="validationContext">
            <b-form-group label="Role Seçiniz" label-for="role_adi" :class="{ 'is-invalid': !!validationContext.errors[0] }">
              <v-select
                v-model="form.role_k_no"
                :options="roller"
                :reduce="(role) => role.k_no"
                label="role_adi"
                :clearable="false"
                class="invoice-filter-select d-block select-size-lg"
                :class="{ 'is-invalid': !!validationContext.errors[0] }"
                :state="getValidationState(validationContext)"
                aria-describedby="input-1-live-feedback"
              >
                <template slot="selected-option" slot-scope="option"> {{ option.acente }} - {{ option.role_adi }} </template>
                <template slot="option" slot-scope="option">
                  <div :class="option.ac_k_no && 'text-info'">{{ option.acente }} - {{ option.role_adi }}</div>
                </template>
              </v-select>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="d-flex justify-content-between align-items-center py-1">
            <label for="statu">Kullanım Durumu</label>
            <b-form-checkbox id="statu" :checked="form.statu" v-model="form.statu" switch size="lg" />
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="6" lg="6">
          <div class="d-flex">
            <b-button squared block type="submit" size="lg" variant="primary" class="mr-2">
              <i class="fad fa-save pr-2"></i>
              <span class="align-middle">{{ form.k_no == null ? 'EKLE' : 'GÜNCELLE' }}</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import vSelect from 'vue-select';
import { defineComponent, ref, computed, toRefs, watch } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
import { useRouter } from '@/libs/utils';
import store from '@/store';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
export default defineComponent({
  components: {
    Treeselect,
    vSelect,
  },

  setup(props, context) {
    const expo = {};

    const toast = useToast();
    const { route } = useRouter();

    expo.acenteler = ref([]);
    expo.roller = ref([]);

    expo.profile = ref(null);
    expo.roller = ref([]);
    expo.master = ref('user');
    expo.passwordFieldType = ref('password');

    expo.form = ref({
      k_no: null,
      ac_k_no: null,
      ad_soyad: null,
      kullanici_adi: null,
      sifre: null,
      telefon: null,
      e_mail: null,
      role_k_no: null,
      statu: true,
    });

    expo.resetForm = () => {
      expo.form.value = {
        k_no: null,
        ac_k_no: null,
        ad_soyad: null,
        kullanici_adi: null,
        sifre: null,
        telefon: null,
        e_mail: null,
        role_k_no: null,
        statu: true,
      };
    };

    expo.sortValueBy = ref('INDEX');

    expo.acenteNormalizer = (node) => {
      return {
        id: node.k_no && node.k_no,
        label: node.firma_adi != undefined && node.firma_adi,
        children: node.children != undefined && node.children.length > 0 && node.children,
      };
    };

    expo.passwordToggleIcon = computed(() => {
      return expo.passwordFieldType.value === 'password' ? 'eye-slash' : 'eye';
    });

    expo.togglePasswordVisibility = () => {
      expo.passwordFieldType.value = expo.passwordFieldType.value === 'password' ? 'text' : 'password';
    };

    expo.userSifreControl = computed(() => {
      if (expo.form.value.k_no != null) {
        return { required: false };
      } else {
        return { required: true, min: 4 };
      }
    });

    const handlerFecthData = async () => {
      const k_no = route.value.params.k_no;

      context.emit('show', true);
      context.emit('title', ' Acente Kullanıcı Ekle');

      await store.dispatch('acenteListele', { statu: true }).then((res) => {
        if (res.data.success) expo.acenteler.value = store.getters.getAcenteler;
        context.emit('show', false);
      });

      if (k_no) {
        await store
          .dispatch('acenteKullaniciFindOne', { k_no })
          .then(async (res) => {
            if (res.data.success) {
              await store.dispatch('acenteKullaniciYetkileriGetir', { k_no: res.data.data.ac_k_no }).then((res) => {
                if (res.data.success) expo.roller.value = res.data.data;
              });

              expo.form.value = { ...res.data.data };

              context.emit('title', expo.form.value.kullanici_adi + ' Acente Kullanıcı Güncelle');
              context.emit('show', false);
            }
          })
          .catch((err) => {
            console.log(err);
            context.emit('show', false);
          });
      }
    };

    handlerFecthData();

    expo.handlerAcenteChange = (event) => {
      if (!route.value.params.k_no) expo.form.value.role_k_no = null;
      store.dispatch('acenteKullaniciYetkileriGetir', { k_no: event }).then((res) => {
        if (res.data.success) expo.roller.value = res.data.data;
      });
    };

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      const base_url = expo.form.value.k_no == null ? 'acenteKullaniciEkle' : 'acenteKullaniciGuncelle';
      store.dispatch(base_url, expo.form.value).then((res) => {
        if (res.data.success === true) {
          toast.success(expo.form.value.k_no == null ? 'Ekleme Başarılı.' : 'Güncelleme Başarılı', { position: 'bottom-left' });
          // context.emit('show', false);
          if (expo.form.value.k_no == null) {
            expo.resetForm();
          }
        } else {
          toast.error(res.data.message, { position: 'bottom-left' });
        }
      });
    };

    return { ...expo };
  },
});
</script>

<style lang="scss"></style>
