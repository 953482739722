<template>
  <div class="agency-item__container" :class="data.statu ? 'aktif' : 'pasif'" v-if="data">
    <div class="agency-item__profile">
      <figure class="agency-profile-image">
        <img :src="handlerLogoShow(data.logo)" alt="" style="background-color: #8d9eaa" />
        <b-button-group class="actions-button">
          <input
            type="file"
            ref="file"
            name="logo"
            id="selectorImagesLogoInput"
            accept="image/jpg,image/jpeg,image/png,image/gif"
            class="selector-images-input"
            @change="handlerOnSumbited"
          />
          <b-button variant="danger" v-if="data.logo" size="sm" @click="handlerLogoRemove">
            <i class="fad fa-trash"></i>
          </b-button>
          <b-button variant="success" size="sm" @click="handlerSelectedLogoImages">
            <i class="fad fa-plus"></i>
          </b-button>
        </b-button-group>
      </figure>
    </div>
    <div class="agency-item__details">
      <h5 class="font-weight-bold">{{ data.firma_adi }}</h5>
      <ul>
        <li v-if="data.yetkili_ad_soyad">
          <span>Yetkili Ad Soyad</span><span>{{ data.yetkili_ad_soyad }}</span>
        </li>
        <li v-if="data.e_mail">
          <span>E Mail</span><span>{{ data.e_mail }}</span>
        </li>
        <li v-if="data.telefon">
          <span>Telefon</span><span>{{ data.telefon }}</span>
        </li>
        <li v-if="data.fax">
          <span>Fax</span><span>{{ data.fax }}</span>
        </li>
        <li v-if="data.web_site">
          <span>Web Site</span><span>{{ data.web_site }}</span>
        </li>
        <li v-if="data.adres">
          <span>Adres</span><span>{{ data.adres }}</span>
        </li>
      </ul>
    </div>
    <div class="agency-item__actions">
      <div class="button-group">
        <b-button class="rounded-0" block variant="info" @click="handlerAcenteSetting(data)" v-if="!data.ac_k_no">
          <i class="fad fa-cogs"></i>
          Ayarlar
        </b-button>
        <b-button class="rounded-0" block variant="info" @click="handlerAcenteSetting(data)" v-else>
          <i class="fad fa-user"></i>
          Kullanıcı Tanımla
        </b-button>
        <b-button
          class="rounded-0"
          block
          variant="warning"
          :to="{
            name: 'acente-guncelle',
            params: { k_no: data.k_no },
          }"
        >
          <i class="fad fa-pencil"></i> Düzenle
        </b-button>
        <b-button class="rounded-0" block :variant="data.statu ? 'success' : 'danger'" @click="handlerAcenteStatu(data)">
          <i :class="data.statu ? 'fad fa-eye-slash' : 'fad fa-eye'"></i>
          {{ data.statu ? 'Pasif Et' : 'Aktif Et' }}
        </b-button>
        <b-button class="rounded-0" block variant="danger"> <i class="fad fa-trash"></i> Sil </b-button>
      </div>
    </div>
    <AcenteAyarlar :data="data" :openModal="openModal" @closeModal="openModal = $event" />
  </div>
</template>

<script>
import { ref, defineComponent, toRefs, computed } from '@vue/composition-api';
import LineChartGenerate from '../chart/LineChartGenerate.vue';
import store from '@/store';

import Swal from 'sweetalert2';
import { useToast } from 'vue-toastification/composition';

import AcenteAyarlar from '@/views/acenteler/listele/AcenteAyarlar.vue';

export default defineComponent({
  components: { LineChartGenerate, AcenteAyarlar },
  props: {
    show: [Boolean],
    data: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const expo = {};
    const { data } = toRefs(props);
    const toast = useToast();
    const fileName = store.getters.getUserFirma.db;

    expo.openModal = ref(false);

    expo.handlerAcenteStatu = (event) => {
      const statu = !event.statu;
      Swal.fire({
        title: 'Uyarı',
        text: data.value.statu
          ? 'Bu Acenteyi Aktif Etmek İstediğinizden Eminmisiniz'
          : 'Bu Acenteyi Pasif Etmek İstediğinizden Eminmisiniz',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          data.value.statu = statu;
          store.dispatch('acenteGuncelle', data.value).then((res) => {
            if (statu) {
              toast.success('Acente Aktif Edildi', { position: 'bottom-left' });
            } else {
              toast.error('Acente Pasif Edildi', { position: 'bottom-left' });
            }
          });
        }
      });
    };

    expo.handlerSelectedLogoImages = () => {
      const file = document.getElementById('selectorImagesLogoInput');
      file.click();
    };

    expo.handlerLogoShow = computed(() => {
      return (val) => {
        const base_url =
          process.env.NODE_ENV === 'development'
            ? process.env.VUE_APP_DEV_BASEURL.slice(0, -3)
            : process.env.VUE_APP_PRO_BASEURL.slice(0, -3);
        if (val != undefined) {
          return `${base_url}uploads/${fileName}/acente-logo/${val}`;
        } else {
          return `${base_url}uploads/no/default.png`;
        }
      };
    });

    expo.handlerOnSumbited = async () => {
      context.emit('show', true);
      let formData = new FormData();
      let forms = context.refs.file.files;
      Object.keys(forms).map(function (key) {
        formData.append('acente-logo', forms[key]);
      });

      formData.append('k_no', data.value.k_no);

      await store
        .dispatch('acenteLogoGuncelle', formData)
        .then((res) => {
          data.value.logo = res.data.data.logo;

          context.emit('show', false);
        })
        .catch((err) => {
          console.log(err);
          context.emit('show', false);
        });
    };

    expo.handlerLogoRemove = async () => {
      await store
        .dispatch('acenteLogoSil', data.value)
        .then((res) => {
          data.value.logo = res.data.data.logo;
          context.emit('show', false);
        })
        .catch((err) => {
          console.log(err);
          context.emit('show', false);
        });
    };

    expo.handlerAcenteSetting = () => {
      expo.openModal.value = true;
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
.agency-item {
  &__container {
    position: relative;
    display: grid;
    grid-template-areas: 'profile details actions';
    grid-template-columns: 180px auto 200px;
    grid-template-rows: auto 1fr auto auto;
    border-radius: 8px;

    width: 100%;
    min-height: 200px;
    transition: all 0.2s ease-in-out;
    background: linear-gradient(to left, #e4e4e4, #ffffff);
    padding: 10px;
    &:hover {
      -webkit-box-shadow: 0px 0px 10px 0px rgba(173, 173, 173, 0.71);
      -moz-box-shadow: 0px 0px 10px 0px rgba(173, 173, 173, 0.71);
      box-shadow: 0px 0px 10px 0px rgba(173, 173, 173, 0.71);
      transition: all 0.2s ease-in-out;
    }
    &.aktif {
      border: 1px solid #e4e4e4;
    }
    &.pasif {
      border: 1px solid #a10000;
    }
  }
  &__profile {
    grid-area: profile;
    width: 100%;
    height: 100%;
    padding: 5px;
    .agency-profile-image {
      position: relative;
      min-height: 180px;
      height: 160px;
      img {
        border-radius: 8px;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .actions-button {
        position: absolute;
        top: 5px;
        right: 5px;
      }
    }
  }
  &__details {
    position: relative;
    grid-area: details;
    padding: 5px;
    width: 100%;
    ul {
      li {
        position: relative;
        display: grid;
        grid-template-columns: 130px 100%;
        padding: 2px 0;
        span:first-child {
          font-weight: 600;
        }
      }
    }
  }
  &__actions {
    grid-area: actions;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding-bottom: 20px;
    &:before {
      position: absolute;
      font-family: 'Font Awesome 5 Pro';
      content: '\f6f1';
      font-size: 10rem;
      z-index: 0;
      color: #fff;
      opacity: 0.5;
      top: -50px;
      right: -30px;
    }
    .button-group {
      z-index: 1;
    }
  }
}
</style>
