<template>
  <div>
    <b-tabs content-class="mt-3" align="center">
      <b-tab title="VİLLA" active @click="handlerTuruSelected('villa')">
        <KomisyonUrun :form="form.villa" :kur="kurTurleri" type="villa" @submit="onSubmit" />
      </b-tab>
      <b-tab title="YAT" @click="handlerTuruSelected('yat')">
        <KomisyonUrun :form="form.yat" :kur="kurTurleri" type="yat" @submit="onSubmit" />
      </b-tab>
      <b-tab title="TUR" @click="handlerTuruSelected('tur')">
        <KomisyonUrun :form="form.tur" :kur="kurTurleri" type="tur" @submit="onSubmit" />
      </b-tab>
      <b-tab title="EMLAK" @click="handlerTuruSelected('emlak')">
        <KomisyonUrun :form="form.emlak" :kur="kurTurleri" type="emlak" @submit="onSubmit" />
      </b-tab>
      <b-tab title="TRANSFER" @click="handlerTuruSelected('transfer')">
        <KomisyonUrun :form="form.transfer" :kur="kurTurleri" type="transfer" @submit="onSubmit" />
      </b-tab>
      <b-tab title="BİLET" @click="handlerTuruSelected('bilet')">
        <KomisyonUrun :form="form.bilet" :kur="kurTurleri" type="bilet" @submit="onSubmit" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { ref, computed, defineComponent } from '@vue/composition-api';
import KomisyonUrun from './component/komisyon/KomisyonUrun';
import store from '@/store';
import { useRouter } from '@/libs/utils';
import { useToast } from 'vue-toastification/composition';
export default defineComponent({
  components: { KomisyonUrun },
  setup(props, context) {
    const expo = {};
    const { route } = useRouter();
    const toast = useToast();
    expo.kurTurleri = computed(() => store.getters.getKurAyarlari);
    expo.loaded = ref(false);
    expo.urun_tipi = ref('villa');

    expo.form = ref({
      ac_k_no: null,
      villa: {
        sabit: {
          urun: {
            turu: 'kapali',
            oran: 0,
          },
          ekstra: {
            turu: 'kapali',
            oran: 0,
          },
        },
        urun_komisyon: [],
      },
      yat: {
        sabit: {
          urun: {
            turu: 'kapali',
            oran: 0,
          },
          ekstra: {
            turu: 'kapali',
            oran: 0,
          },
        },
        urun_komisyon: [],
      },
      tur: {
        sabit: {
          urun: {
            turu: 'kapali',
            oran: 0,
          },
          ekstra: {
            turu: 'kapali',
            oran: 0,
          },
        },
        urun_komisyon: [],
      },
      emlak: {
        sabit: {
          urun: {
            turu: 'kapali',
            oran: 0,
          },
          ekstra: {
            turu: 'kapali',
            oran: 0,
          },
        },
        urun_komisyon: [],
      },
      transfer: {
        sabit: {
          urun: {
            turu: 'kapali',
            oran: 0,
          },
          ekstra: {
            turu: 'kapali',
            oran: 0,
          },
        },
        urun_komisyon: [],
      },
      bilet: {
        sabit: {
          urun: {
            turu: 'kapali',
            oran: 0,
          },
          ekstra: {
            turu: 'kapali',
            oran: 0,
          },
        },
        urun_komisyon: [],
      },
    });

    expo.handlerTuruSelected = (event) => {
      expo.urun_tipi.value = event;
      handlerFetchAllData();
    };

    const handlerFetchAllData = async () => {
      context.emit('show', true);
      context.emit('title', 'Komisyon Ayarları');
      const k_no = route.value.params.k_no;
      if (k_no) {
        expo.form.value.ac_k_no = k_no;

        await store
          .dispatch('urunSiralamaListele', {
            urun_tipi: expo.urun_tipi.value,
          })
          .then((res) => {
            context.emit('show', false);
          });

        await store.dispatch('acenteKomisyonListele', { ac_k_no: k_no }).then((res) => {
          if (res.data.success) expo.form.value = res.data.data;
        });
      }
    };

    handlerFetchAllData();

    expo.onSubmit = async () => {
      expo.loaded.value = true;
      await store.dispatch('acenteKomisyonGuncelle', expo.form.value).then((res) => {
        if (res.data.success) {
          toast.success('Güncelleme başarılı.', { position: 'bottom-left' });
          expo.loaded.value = false;
        }
      });
    };

    return { ...expo };
  },
});
</script>

<style scoped></style>
