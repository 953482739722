<template>
  <div>
    <b-modal :visible="openModal" @change="(val) => $emit('closeModal', false)" class="rounded-0" centered title="Tahsilat | Ödeme İşlemi">
      <b-card-text>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Tahsilat | Ödeme">
              <v-select
                v-model="form.gc_kodu"
                :options="gcKodu"
                :reduce="(kok) => kok.value"
                label="title"
                :clearable="false"
                class="invoice-filter-select d-block select-size-lg"
                @input="handlerGCKoduChange($event)"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="İşlem Tarihi">
              <flat-pickr
                v-model="date"
                class="form-control form-control-lg rounded-0 bg-white"
                :config="config"
                :events="config.events"
                @on-change="handlerOnChangeDate"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Tutar">
              <b-input-group>
                <b-form-input
                  type="number"
                  size="lg"
                  class="rounded-0 text-right"
                  ref="baslik"
                  v-model="form.secilen_tutar"
                  @input="handlerKurChange(form.secilen_kur)"
                />
                <b-input-group-prepend>
                  <v-select
                    v-model="form.secilen_kur"
                    :options="kurlar"
                    :reduce="(kok) => kok.banka_kodu"
                    label="banka_kodu"
                    :clearable="false"
                    class="invoice-filter-select d-block select-size-lg"
                    style="width: 150px"
                    @input="handlerKurChange($event)"
                  />
                </b-input-group-prepend>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Açıklama">
              <b-form-textarea size="lg" class="rounded-0" ref="baslik" v-model="form.aciklama" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-text>
      <template #modal-footer>
        <div class="w-100">
          <b-button squared variant="primary" class="float-right" type="submit" @click="onSubmit" :disabled="show">
            <i class="fad fa-save" v-if="!show" />
            <b-spinner class="mr-25" small variant="light" v-else />
            {{ form.k_no == null ? 'KAYDET' : 'GÜNCELLE' }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import store from '@/store';
import moment from 'moment';
import vSelect from 'vue-select';
import { useToast } from 'vue-toastification/composition';
import 'flatpickr/dist/flatpickr.css';
import flatPickr from 'vue-flatpickr-component';
import { Turkish } from 'flatpickr/dist/l10n/tr.js';
import { ref, toRefs, watch, computed, onMounted } from '@vue/composition-api';

import { sabitKuraCevir } from '@/libs/global';
export default {
  components: {
    vSelect,
    flatPickr,
  },

  props: {
    openModal: {
      type: Boolean,
      required: true,
    },
    updateData: {
      type: Object,
      required: false,
    },
    agency: {
      type: Object,
      required: false,
    },
    toplam: {
      type: Object,
      required: false,
    },
  },

  setup(props, context) {
    const expo = {};
    const toast = useToast();
    const { updateData, agency, toplam } = toRefs(props);
    expo.kurlar = ref(store.getters.getKurListesi);
    expo.kurTurleri = computed(() => store.getters.getKurAyarlari);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.show = ref(false);

    expo.gcKodu = ref([
      { value: 'G', title: 'Tahsilat' },
      { value: 'C', title: 'Ödeme' },
    ]);

    expo.form = ref({
      k_no: null,
      ac_k_no: null,
      islem_tarihi: moment().format('YYYY-MM-DD'),
      sabit_kur: expo.kurTurleri.value.varsayilan,
      sabit_kur_fiyat: null,
      sabit_tutar: 0,
      secilen_kur: expo.kurTurleri.value.varsayilan,
      secilen_kur_fiyat: null,
      secilen_tutar: null,
      gc_kodu: 'C',
      aciklama: null,
      statu: 'odeme',
    });

    expo.date = ref(moment().format('DD.MM.YYYY'));

    expo.config = ref({
      wrap: true,
      events: ['onChange', 'onClose', 'onDayCreate', 'onStartChange'],
      inline: false,
      mode: 'single',
      dateFormat: 'd.m.Y',
      locale: Turkish, // locale for this instance only
    });

    expo.handlerOnChangeDate = (event) => {
      if (event) expo.form.value.islem_tarihi = moment(String(event)).format('YYYY-MM-DD');
    };

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      expo.show.value = true;
      const baseUrl = expo.form.value.k_no == null ? 'acenteHareketEkle' : 'acenteHareketGuncelle';
      store
        .dispatch(baseUrl, expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            toast.success(expo.form.value.k_no == null ? 'Ekleme Başarılı' : 'Güncelleme Başarılı', { position: 'bottom-left' });
            expo.show.value = false;
            context.emit('toplamUpdate', { ...toplam.value, ...res.data.toplam });
          } else {
            expo.show.value = false;
            toast.error(res.data.message, { position: 'bottom-left' });
          }
        })
        .catch((err) => {
          console.log(err);
          expo.show.value = false;
          if (err.success == false) {
            toast.error(Object.keys(err.data.dup).length > 0 ? Object.keys(err.data.dup)[0] + ' ' + err.data.message : err.data.message, {
              position: 'bottom-left',
            });
          }
        });
    };

    expo.handlerKurChange = (event) => {
      if (expo.form.value.sabit_kur != expo.form.value.secilen_kur) {
        expo.form.value.sabit_tutar = sabitKuraCevir(expo.form.value.sabit_tutar, expo.form.value.sabit_kur, expo.form.value.secilen_kur);
      } else {
        expo.form.value.sabit_tutar = expo.form.value.secilen_tutar;
      }
    };

    expo.handlerGCKoduChange = (event) => {
      expo.form.value.gc_kodu = event;
      expo.form.value.statu = event == 'C' ? 'odeme' : 'tamamlandi';
    };

    watch(
      expo.kurTurleri,
      (val) => {
        if (val) {
          const kur = expo.kurlar.value.find((x) => x.banka_kodu == expo.kurTurleri.value.varsayilan);
          expo.form.value.sabit_kur = kur.banka_kodu;
          expo.form.value.sabit_kur_fiyat = kur.satis;
          expo.form.value.secilen_kur = kur.banka_kodu;
          expo.form.value.secilen_kur_fiyat = kur.satis;
          console.log(agency);
          expo.form.value.ac_k_no = agency.value.ac_k_no;
        }
      },
      { deep: true, immediate: true }
    );

    watch(updateData, (val) => {
      if (val) {
        expo.form.value = val;
      }
    });
    return { ...expo };
  },
};
</script>

<style lang="scss" scoped></style>
