<template>
  <b-modal
    :visible="openModal"
    @change="(val) => $emit('closeModal', false)"
    size="lg"
    class="rounded-0"
    centered
    title="Alış Bölgesi Ekle"
  >
    <b-card-text>
      <b-alert v-if="!urunler" show variant="warning" class="text-center">
        <p>Gösterilecek veri yok !</p>
      </b-alert>

      <vue-good-table
        :columns="columns"
        :rows="urunler"
        :line-numbers="false"
        :pagination-options="{
          enabled: false,
          perPage: 100,
          mode: 'pages',
        }"
        @on-selected-rows-change="selectionChanged"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true,
          selectionText: 'Satır Secildi.',
          clearSelectionText: 'Temizle',
        }"
        :search-options="{ enabled: true }"
        styleClass="vgt-table striped bordered"
      >
        <div slot="selected-row-actions">
          <b-button squared variant="primary" v-b-tooltip.hover="'Toplu İşlem'" @click="onSubmit">
            <i class="fad fa-save" /> Seçili Satırları Aktar
          </b-button>
        </div>
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'baslik'">
            <span class="font-weight-bold d-block text-dark">
              {{ props.row.baslik }}
            </span>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </b-card-text>
    <template #modal-footer>
      <div class="w-100">
        <b-button squared variant="primary" class="float-right" type="submit" @click="onSubmit">
          <i class="fad fa-save" />
          Seçili Satırları Aktar
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import store from '@/store';
import Swal from 'sweetalert2';
import { VueGoodTable } from 'vue-good-table';
import { useToast } from 'vue-toastification/composition';
import { ref, toRefs, watch, computed, onMounted } from '@vue/composition-api';

export default {
  components: {
    VueGoodTable,
  },
  props: {
    openModal: {
      type: Boolean,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();
    const { form, openModal } = toRefs(props);
    expo.urunler = ref(null);
    expo.multiSelectRow = ref([]);

    expo.columns = ref([
      {
        label: 'Ürün Bilgisi',
        field: 'baslik',
      },
    ]);

    const urunler = computed(() => store.getters.getUrunler);

    const handlerUrunler = () => {
      expo.urunler.value = [];
      const seciliUrunler = form.value.urun_komisyon;

      urunler.value.forEach((el) => {
        if (el.k_no != null) {
          expo.urunler.value.push({
            k_no: el.k_no,
            baslik: el.baslik,
          });
        }
      });

      let common = expo.urunler.value.filter((obj1) => seciliUrunler.some((obj2) => obj1.k_no === obj2.k_no));

      common.forEach((el) => {
        expo.urunler.value.splice(expo.urunler.value.indexOf(el), 1);
      });
    };

    expo.selectionChanged = (event) => {
      expo.multiSelectRow.value = [];
      event.selectedRows.forEach((el) => {
        expo.multiSelectRow.value.push({
          k_no: el.k_no,
          komisyon: {
            turu: 'yuzde',
            oran: 0,
          },
        });
      });
    };

    expo.onSubmit = () => {
      expo.multiSelectRow.value;
      const selectedUrun = expo.multiSelectRow.value;
      if (selectedUrun.length) {
        selectedUrun.forEach((el) => {
          if (form.value.urun_komisyon.indexOf(el) === -1) {
            form.value.urun_komisyon.push(el);
          }
        });
        context.emit('closeModal', false);
      } else {
        toast.warning('Lütfen Bölge Seçimi Yapınız');
      }
    };

    watch(
      openModal,
      (val) => {
        if (val) handlerUrunler();
      },
      { deep: true }
    );

    return { ...expo };
  },
};
</script>

<style lang="scss" scoped></style>
