var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vue-good-table',{attrs:{"mode":"remote","columns":_vm.columns,"rows":_vm.rows,"line-numbers":true,"pagination-options":{
      enabled: true,
      perPage: 10,
      position: 'bottom',
      perPageDropdown: [5, 10, 20, 30, 50, 70, 100],
      dropdownAllowAll: true,
      firstLabel: 'İlk Sayfa',
      lastLabel: 'Son Sayfa',
      nextLabel: 'İleri',
      prevLabel: 'Geri',
      rowsPerPageLabel: 'Sayfa Başı Satır Sayısı',
      ofLabel: 've',
      pageLabel: 'Sayfa', // for 'pages' mode
      allLabel: 'Tümü',
    },"search-options":{
      enabled: true,
      trigger: 'enter',
      placeholder: 'Tur Ara',
    },"isLoading":_vm.isLoading,"totalRows":_vm.count,"sort-options":{
      enabled: false,
    },"styleClass":"vgt-table striped bordered"},on:{"on-search":_vm.onSearchFilter,"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'firma_adi')?_c('span',[_c('router-link',{attrs:{"to":{
            name: 'acente-detail',
            params: { k_no: props.row.k_no },
          }}},[_c('span',{staticClass:"d-block text-dark font-weight-bold"},[_vm._v(_vm._s(props.row.firma_adi))])])],1):(props.column.field === 'uye_grubu')?_c('span',[_c('span',{staticClass:"rounded-0 bg-info text-light p-1 d-block text-center"},[_vm._v(" "+_vm._s(props.row.uye_grup)+" ")])]):(props.column.field === 'iletisim')?_c('span',[_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(props.row.telefon))]),_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(props.row.e_mail))])]):(props.column.field === 'bakiye')?_c('span',[(props.row.ac_bakiye)?_c('span',{staticClass:"rounded-0 text-light p-1 d-block text-center",class:props.row.ac_bakiye.kalan > 0 ? 'bg-success' : 'bg-danger'},[_vm._v(" "+_vm._s(_vm._f("cur")(props.row.ac_bakiye.kalan))+" "+_vm._s(props.row.sabit_kur)+" ")]):_vm._e()]):(props.column.field === 'statu')?_c('span',[_c('b-form-checkbox',{staticClass:"custom-control-info",attrs:{"switch":"","size":"lg"},on:{"change":function($event){return _vm.handlerStatu(props.row.k_no, $event)}},model:{value:(props.row.statu),callback:function ($$v) {_vm.$set(props.row, "statu", $$v)},expression:"props.row.statu"}})],1):(props.column.field === 'action')?_c('span',{staticClass:"float-right px-1"},[_c('b-button-group',{staticClass:"pb-25",attrs:{"size":"sm"}},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Acente Bilgileri'),expression:"'Acente Bilgileri'",modifiers:{"hover":true,"top":true}}],staticClass:"rounded-0",attrs:{"variant":"info","to":{
              name: 'acente-detail',
              params: { k_no: props.row.k_no },
            }}},[_c('i',{staticClass:"fad fa-eye"})]),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Acente Güncelle'),expression:"'Acente Güncelle'",modifiers:{"hover":true,"top":true}}],staticClass:"rounded-0",attrs:{"variant":"warning","to":{
              name: 'acente-guncelle',
              params: { k_no: props.row.k_no },
            }}},[_c('i',{staticClass:"fad fa-edit"})]),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Acente Sil'),expression:"'Acente Sil'",modifiers:{"hover":true,"top":true}}],staticClass:"rounded-0",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.handlerRemove(props.row.k_no)}}},[_c('i',{staticClass:"fad fa-trash"})])],1)],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }