<template>
  <div class="p-3">
    <validation-observer ref="observer" v-slot="{ handleSubmit }" class="">
      <b-form @submit.stop.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm" autocomplete="off">
        <b-row>
          <b-col cols="12" md="6">
            <b-form-group label="Ana Acente">
              <v-select
                v-model="form.ac_k_no"
                :options="acenteler"
                :reduce="(kur) => kur.k_no"
                label="firma_adi"
                :clearable="true"
                class="select-size-lg"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <validation-provider name="Firma Adı" :rules="{ required: true }" v-slot="validationContext">
              <b-form-group label="Firma Adı">
                <b-form-input
                  size="lg"
                  class="rounded-0"
                  v-model="form.firma_adi"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-1-live-feedback"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <validation-provider name="Fatura Başlığı" :rules="{ required: true }" v-slot="validationContext">
              <b-form-group label="Fatura Başlığı">
                <b-form-input
                  size="lg"
                  class="rounded-0"
                  v-model="form.fatura_basligi"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-1-live-feedback"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="4">
            <validation-provider name="Yetkili Ad Soyad" :rules="{ required: true }" v-slot="validationContext">
              <b-form-group label="Yetkili Ad Soyad">
                <b-form-input
                  size="lg"
                  class="rounded-0"
                  v-model="form.yetkili_ad_soyad"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-1-live-feedback"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group label="Vergi Dairesi">
              <b-form-input size="lg" class="rounded-0" v-model="form.vergi_dairesi" aria-describedby="input-1-live-feedback" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group label="Vergi No">
              <b-form-input size="lg" class="rounded-0" v-model="form.vergi_no" aria-describedby="input-1-live-feedback" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6">
            <validation-provider name="E Posta" :rules="{ required: true, email: true }" v-slot="validationContext">
              <b-form-group label="E Posta">
                <b-form-input
                  size="lg"
                  class="rounded-0"
                  v-model="form.e_mail"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-1-live-feedback"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group label="Web Site">
              <b-form-input size="lg" class="rounded-0" v-model="form.web_site" aria-describedby="input-1-live-feedback" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <validation-provider name="Telefon" :rules="{ required: true }" v-slot="validationContext">
              <b-form-group label="Telefon">
                <b-form-input
                  size="lg"
                  class="rounded-0"
                  v-mask="'+## ### ### ####'"
                  v-model="form.telefon"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-1-live-feedback"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group label="Fax">
              <b-form-input
                size="lg"
                class="rounded-0"
                v-mask="'+## ### ### ####'"
                v-model="form.fax"
                aria-describedby="input-1-live-feedback"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Adres">
              <b-form-input size="lg" class="rounded-0" v-model="form.adres" aria-describedby="input-1-live-feedback" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4">
            <validation-provider name="Ulke" :rules="{ required: true }" v-slot="validationContext">
              <b-form-group label="Ulke">
                <b-form-input
                  size="lg"
                  class="rounded-0"
                  v-model="form.ulke"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-1-live-feedback"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="4">
            <validation-provider name="Şehir" :rules="{ required: true }" v-slot="validationContext">
              <b-form-group label="Şehir">
                <b-form-input
                  size="lg"
                  class="rounded-0"
                  v-model="form.sehir"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-1-live-feedback"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="4">
            <validation-provider name="İlçe" :rules="{ required: true }" v-slot="validationContext">
              <b-form-group label="İlçe">
                <b-form-input
                  size="lg"
                  class="rounded-0"
                  v-model="form.ilce"
                  :state="getValidationState(validationContext)"
                  aria-describedby="input-1-live-feedback"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Açıklama ( Not )">
              <b-form-textarea size="lg" class="rounded-0" v-model="form.aciklama" aria-describedby="input-1-live-feedback" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6">
            <div class="d-flex justify-content-between align-items-center py-1">
              <label class="font-weight-bold h6 mt-2">Kullanım Durumu</label>
              <b-form-checkbox :checked="form.statu" v-model="form.statu" switch size="lg" />
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6" lg="6">
            <div class="d-flex">
              <b-button squared block type="submit" size="lg" variant="success" class="mr-2">
                <i class="fad fa-save pr-2"></i>
                <span class="align-middle">KAYDET</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import store from '@/store';
import vSelect from 'vue-select';
import { useToast } from 'vue-toastification/composition';
import { ref, toRefs, computed, defineComponent, watch } from '@vue/composition-api';
import { useRouter } from '@/libs/utils';
export default defineComponent({
  components: { vSelect },
  props: {
    new_add: [Boolean],
  },
  setup(props, context) {
    const expo = {};
    const { route, router } = useRouter();
    const { new_add } = toRefs(props);
    const toast = useToast();
    expo.loaded = ref(false);

    expo.acenteler = computed(() => store.getters.getAcenteler);

    expo.form = ref({
      ac_k_no: null,
      firma_adi: null,
      fatura_basligi: null,
      vergi_dairesi: null,
      vergi_no: null,
      yetkili_ad_soyad: null,
      telefon: null,
      e_mail: null,
      fax: null,
      web_site: null,
      ulke: null,
      sehir: null,
      ilce: null,
      adres: null,
      aciklama: null,
      statu: true,
    });

    const handlerFormTemizle = () => {
      context.emit('title', ' Acente Ekle');

      expo.form.value = {
        ac_k_no: null,
        firma_adi: null,
        fatura_basligi: null,
        vergi_dairesi: null,
        vergi_no: null,
        yetkili_ad_soyad: null,
        telefon: null,
        e_mail: null,
        fax: null,
        web_site: null,
        ulke: null,
        sehir: null,
        ilce: null,
        adres: null,
        aciklama: null,
        statu: true,
      };
    };
    const handlerFetchData = () => {
      const k_no = route.value.params.k_no;

      if (k_no) {
        context.emit('show', true);

        store
          .dispatch('acenteFindOne', { k_no })
          .then((res) => {
            if (res.data.success) {
              expo.form.value = res.data.data;

              context.emit('title', expo.form.value.firma_adi + ' Acente Güncelle');
              context.emit('show', false);
            }
          })
          .catch((err) => {
            console.log(err);
            context.emit('show', false);
          });
      } else {
        handlerFormTemizle();
      }
    };

    handlerFetchData();

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = async () => {
      expo.loaded.value = true;
      const baseUrl = expo.form.value.k_no ? 'acenteGuncelle' : 'acenteEkle';
      await store.dispatch(baseUrl, expo.form.value).then((res) => {
        if (res.data.success) {
          if (expo.form.value.k_no) {
            toast.success('Güncelleme Başarılı', { position: 'bottom-left' });
          } else {
            toast.success('Ekleme Başarılı', { position: 'bottom-left' });
          }
          expo.loaded.value = false;
        }
      });
    };

    watch(route, async (val) => {
      if (val.name == 'acente-ekle') handlerFormTemizle();
    });
    return { ...expo };
  },
});
</script>

<style lang="scss">
.b-sidebar {
  .b-sidebar-body {
    overflow: hidden;
    overflow-y: auto;
  }
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 0px;
  border-bottom: 1px solid #ebe9f1;
  h5 {
    color: #6e6b7b;
  }
  i {
    font-size: 1.5rem;
  }
}

.sidebar-body {
  padding: 0 10px 10px 10px;
}
</style>
