<template>
  <div class="p-2">
    <b-input-group>
      <b-form-input
        size="lg"
        class="rounded-0"
        ref="role_adi"
        aria-describedby="input-1-live-feedback"
        placeholder="Acente Ara... ( Aramak istediğiniz Acente ünvanı yazın ve Enter'a basın. )"
        @input="handlerOnSearch"
      />
    </b-input-group>
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :line-numbers="true"
      :pagination-options="{
        enabled: true,
        perPage: 100,
      }"
      styleClass="vgt-table striped bordered"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'firma_adi'">
          <router-link
            :to="{
              name: 'acente-detail',
              params: { k_no: props.row.k_no },
            }"
          >
            <span class="d-block text-dark font-weight-bold">{{ props.row.firma_adi }}</span>
          </router-link>
        </span>
        <span v-else-if="props.column.field === 'uye_grubu'">
          <span class="rounded-0 bg-info text-light p-1 d-block text-center">
            {{ props.row.uye_grup }}
          </span>
        </span>
        <span v-else-if="props.column.field === 'iletisim'">
          <span class="d-block">{{ props.row.telefon }}</span>
          <span class="d-block">{{ props.row.e_mail }}</span>
        </span>
        <span v-else-if="props.column.field === 'bakiye'">
          <span
            class="rounded-0 text-light p-1 d-block text-center"
            :class="props.row.ac_bakiye.kalan > 0 ? 'bg-success' : 'bg-danger'"
            v-if="props.row.ac_bakiye"
          >
            {{ props.row.ac_bakiye.kalan | cur }} {{ props.row.sabit_kur }}
          </span>
        </span>
        <span v-else-if="props.column.field === 'statu'">
          <b-form-checkbox
            class="custom-control-info"
            v-model="props.row.statu"
            switch
            @change="handlerStatu(props.row.k_no, $event)"
            size="lg"
          />
        </span>
        <span v-else-if="props.column.field === 'action'" class="float-right px-1">
          <b-button-group size="sm" class="pb-25">
            <b-button
              variant="info"
              class="rounded-0"
              :to="{
                name: 'acente-detail',
                params: { k_no: props.row.k_no },
              }"
              v-b-tooltip.hover.top="'Acente Bilgileri'"
            >
              <i class="fad fa-eye" />
            </b-button>
            <b-button
              class="rounded-0"
              variant="warning"
              v-b-tooltip.hover.top="'Acente Güncelle'"
              :to="{
                name: 'acente-guncelle',
                params: { k_no: props.row.k_no },
              }"
            >
              <i class="fad fa-edit" />
            </b-button>
            <b-button variant="danger" class="rounded-0" v-b-tooltip.hover.top="'Acente Sil'" @click="handlerRemove(props.row.k_no)">
              <i class="fad fa-trash" />
            </b-button>
          </b-button-group>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template slot="pagination-bottom">
        <Pagination :count="count" @handlerPageSizeChange="handlerPageSizeChange($event)" @handlerPageChange="handlerPageChange($event)" />
      </template>
    </vue-good-table>
  </div>
</template>
<script>
import store from '@/store';
import Swal from 'sweetalert2';
import { VueGoodTable } from 'vue-good-table';
import { useToast } from 'vue-toastification/composition';
import BTableCard from '@/components/cards/BTableCard.vue';
import { defineComponent, ref, computed, watch } from '@vue/composition-api';
import axiosIns from '@/libs/axios';
import { useRouter } from '@/libs/utils';
import Pagination from '../component/Pagination.vue';
import debounce from 'lodash/debounce';

export default defineComponent({
  components: {
    BTableCard,
    VueGoodTable,
    BTableCard,
    Pagination,
  },
  setup(_, context) {
    const expo = {};
    const { route, router } = useRouter();
    const toast = useToast();
    expo.diller = computed(() => store.getters.getDilAyarlari.diller);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.exhange = computed(() => store.getters.getExchange);
    expo.kurlar = computed(() => store.getters.getKurListesi);
    expo.count = ref(0);

    expo.columns = ref([
      {
        label: 'Acente Adı ( Ünvan )',
        field: 'firma_adi',
      },
      {
        label: 'İletişim',
        field: 'iletisim',
        width: '17%',
        thClass: 'text-right',
        thClass: 'text-right',
      },

      {
        label: 'Bakiye',
        field: 'bakiye',
        width: '10%',
        thClass: 'text-right',
        thClass: 'text-right',
      },
      {
        label: 'Statu',
        field: 'statu',
        width: '5%',
        thClass: 'text-right',
        tdClass: 'text-right',
      },
      {
        label: 'İşlemler',
        field: 'action',
        width: '10%',
        thClass: 'text-right',
        tdClass: 'text-right',
      },
    ]);
    expo.rows = ref([]);

    const handlerFetchAllData = async () => {
      context.emit('show', true);
      context.emit('title', 'Acente Listele');

      route.value.query.page = route.value.query.page ? parseInt(route.value.query.page) : 1;
      route.value.query.page_size = route.value.query.page_size ? parseInt(route.value.query.page_size) : 20;

      await store.dispatch('acenteListele', route.value.query).then((res) => {
        if (res.data.success) {
          expo.rows.value = store.getters.getAcenteler;
          expo.count.value = res.data.count;
          context.emit('total', expo.count.value);
        }
      });

      context.emit('show', false);
    };

    expo.handlerOnSearch = debounce((event) => {
      if (event) {
        if (route.value.query.search != event) {
          router.push({
            query: { ...route.value.query, search: event },
          });
        }
      } else {
        router.push({
          query: {},
        });
      }
    }, 400);

    expo.handlerPageSizeChange = (event) => {
      router.push({
        query: { ...route.value.query, page_size: event },
      });
    };

    expo.handlerPageChange = (event) => {
      router.push({
        query: { ...route.value.query, page: event },
      });
    };

    expo.handlerRemove = (k_no) => {
      Swal.fire({
        title: 'Uyarı',
        text: 'Tüm Veriler Silinecektir. Eminmisiniz ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          store.dispatch('acenteSil', k_no).then((res) => {
            if (res.data.success === true) {
              toast.error('Silme Başarılı', { position: 'bottom-left' });
            }
          });
        }
      });
    };

    expo.handlerStatu = async (k_no, event) => {
      if (k_no != null) {
        await axiosIns.post('/acente/acente-statu-guncelle', { k_no, statu: event }).then((res) => {
          if (res.data.success === true) {
            toast.success('Güncelleme Başarılı', { position: 'bottom-left' });
          }
        });
      }
    };

    watch(
      route,
      (val) => {
        if (val.hash != '#' && val.name == 'acente-listele') {
          handlerFetchAllData();
        }
      },
      { immediate: true }
    );

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped>
table.vgt-table {
  font-size: 14px;
  thead {
    background-color: rgba(#000, 0.07);
  }
  td {
    padding: 3px 0px 0px 5px !important;
    vertical-align: middle !important;
  }
}
</style>
