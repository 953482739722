<template>
  <div>
    <b-row>
      <b-col cols="12" md="8">
        <b-card no-body class="rounded-0 h-100">
          <b-card-header class="bg-transparent border-0"> <h6>İSTATİSTİK</h6> </b-card-header>
          <b-card-body>
            <b-row>
              <b-col cols="12" md="3">
                <div class="d-flex align-items-center">
                  <div class="pr-2">
                    <b-avatar variant="warning"> <i class="fad fa-user"></i></b-avatar>
                  </div>
                  <div class="d-flex flex-column">
                    <h6>{{ toplam.beklemede | cur }} {{ toplam.kur }}</h6>
                    <h6 class="text-warning">Beklemede</h6>
                  </div>
                </div>
              </b-col>
              <b-col cols="12" md="3">
                <div class="d-flex align-items-center">
                  <div class="pr-2">
                    <b-avatar variant="danger"> <i class="fad fa-user"></i></b-avatar>
                  </div>
                  <div class="d-flex flex-column">
                    <h6>{{ toplam.iptal | cur }} {{ toplam.kur }}</h6>
                    <h6 class="text-danger">İptal</h6>
                  </div>
                </div>
              </b-col>
              <b-col cols="12" md="3">
                <div class="d-flex align-items-center">
                  <div class="pr-2">
                    <b-avatar variant="info"> <i class="fad fa-user"></i></b-avatar>
                  </div>
                  <div class="d-flex flex-column">
                    <h6>{{ toplam.tamamlandi | cur }} {{ toplam.kur }}</h6>
                    <h6 class="text-info">Hakediş</h6>
                  </div>
                </div>
              </b-col>
              <b-col cols="12" md="3">
                <div class="d-flex align-items-center">
                  <div class="pr-2">
                    <b-avatar variant="success"> <i class="fad fa-user"></i></b-avatar>
                  </div>
                  <div class="d-flex flex-column">
                    <h6>{{ toplam.odeme | cur }} {{ toplam.kur }}</h6>
                    <h6 class="text-success">Ödenen</h6>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12" md="4">
        <b-card no-body class="rounded-0" :class="toplam.kalan > 0 ? 'border-success' : 'border-danger'">
          <b-card-header class="bg-transparent border-0 d-flex justify-content-between align-items-center">
            <h6>KALAN BAKİYE</h6>
            <b-button size="sm" variant="success" @click="openModalFinans = true"> KAYIT EKLE </b-button>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col cols="12">
                <div class="d-flex align-items-center">
                  <div class="pr-2">
                    <b-avatar :variant="toplam.kalan > 0 ? 'success' : 'danger'" size="4rem"> <i class="fad fa-user"></i></b-avatar>
                  </div>
                  <div class="d-flex flex-column" :class="toplam.kalan > 0 ? 'text-success' : 'text-danger'">
                    <h5>{{ toplam.kalan | cur }} {{ toplam.kur }}</h5>
                    <h5>{{ toplam.kalan > 0 ? 'ALACAKLI' : 'BORÇLU' }}</h5>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <vue-good-table
          mode="remote"
          :columns="columns"
          :rows="rows"
          :line-numbers="false"
          :pagination-options="{
            enabled: true,
            perPage: 20,
            position: 'bottom',
            perPageDropdown: [5, 10, 20, 30, 50, 70, 100],
            dropdownAllowAll: true,
            firstLabel: 'İlk Sayfa',
            lastLabel: 'Son Sayfa',
            nextLabel: 'İleri',
            prevLabel: 'Geri',
            rowsPerPageLabel: 'Sayfa Başı Satır Sayısı',
            ofLabel: 've',
            pageLabel: 'Sayfa', // for 'pages' mode
            allLabel: 'Tümü',
          }"
          :search-options="{
            enabled: true,
            trigger: 'enter',
            placeholder: '',
          }"
          @on-search="onSearchFilter"
          @on-page-change="onPageChange"
          @on-per-page-change="onPerPageChange"
          :isLoading="isLoading"
          :totalRows="count"
          :sort-options="{
            enabled: false,
          }"
          styleClass="vgt-table striped bordered condensed"
        >
          <template #table-actions>
            <!-- <BButton class="rounded-0 mx-2" size="sm" variant="primary" :to="{ name: 'user-add' }"> Yeni Kayıt </BButton> -->
          </template>
          <template #emptystate> <h4 class="text-center py-3">Gösterilecek kayıt yoktur !</h4> </template>

          <template #table-row="props">
            <span v-if="props.column.field === 'tarih'">
              <b-badge variant="" class="d-block rounded-0 font-weight-light" style="font-size: 12px">
                {{ props.row.islem_tarihi | momentFull }}
              </b-badge>
              <b-badge class="d-block rounded-0 font-weight-light text-left" style="font-size: 12px" v-if="props.row.rez_k_no">
                # {{ props.row.rez_k_no }}
              </b-badge>
            </span>
            <span v-else-if="props.column.field === 'urun_adi'">
              <b-badge
                variant="warning"
                class="rounded-0 font-weight-light d-block text-left"
                style="font-size: 13px"
                v-if="props.row.urun_adi"
              >
                {{ props.row.urun_adi['tr'] }}
                <span v-if="props.row.urun_tipi == 'transfer'"> -> {{ props.row.nereden['tr'] }} < - > {{ props.row.nereye['tr'] }} </span>
              </b-badge>
              <b-badge
                :variant="props.row.statu == 'odeme' ? 'success' : 'secondary'"
                class="rounded-0 font-weight-light"
                style="font-size: 11px"
              >
                {{ props.row.aciklama }}
              </b-badge>
            </span>
            <span v-else-if="props.column.field === 'gc_kodu'">
              <b-badge
                :variant="props.row.gc_kodu == 'G' ? 'success' : 'danger'"
                class="rounded-0 font-weight-light"
                style="font-size: 12px"
              >
                {{ props.row.gc_kodu == 'G' ? 'Giriş' : 'Çıkış' }}
              </b-badge>
            </span>
            <span v-else-if="props.column.field === 'tutar'">
              <b-badge
                variant="warning"
                class="rounded-0 font-weight-light"
                style="font-size: 12px"
                v-if="props.row.sabit_kur != props.row.secilen_kur"
              >
                {{ props.row.secilen_tutar | cur }} {{ props.row.secilen_kur }}
              </b-badge>
              <b-badge :variant="props.row.gc_kodu == 'G' ? 'info' : 'success'" class="rounded-0 font-weight-light" style="font-size: 12px">
                {{ props.row.sabit_tutar | cur }} {{ props.row.sabit_kur }}
              </b-badge>
            </span>
            <span v-else-if="props.column.field === 'statu'">
              <b-badge
                :variant="
                  props.row.statu == 'beklemede'
                    ? 'warning'
                    : props.row.statu == 'iptal'
                    ? 'danger'
                    : props.row.statu == 'tamamlandi'
                    ? 'info'
                    : 'success'
                "
                class="rounded-0 font-weight-light"
                style="font-size: 12px"
              >
                {{ props.row.statu }}
              </b-badge>
            </span>
            <span v-else-if="props.column.field === 'actions'">
              <BButtonGroup>
                <BButton
                  squared
                  variant="warning"
                  size="sm"
                  @click="handlerUpdate(props.row)"
                  v-if="!props.row.rez_k_no && !props.row.urun_k_no"
                >
                  <i class="fad fa-edit" />
                </BButton>
                <BButton
                  squared
                  variant="danger"
                  size="sm"
                  @click="handlerRemove({ k_no: props.row.k_no, ac_k_no: props.row.ac_k_no })"
                  v-if="!props.row.rez_k_no && !props.row.urun_k_no"
                >
                  <i class="fad fa-trash" />
                </BButton>
              </BButtonGroup>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </b-col>
    </b-row>

    <AcenteFinansEkle
      :openModal="openModalFinans"
      @closeModal="openModalFinans = $event"
      :updateData="updateData"
      :agency="serverParams"
      @toplamUpdate="toplam = $event"
    />
  </div>
</template>

<script>
import store from '@/store';
import Swal from 'sweetalert2';
import { useRouter } from '@/libs/utils';
import { VueGoodTable } from 'vue-good-table';
import { useToast } from 'vue-toastification/composition';
import { ref, computed, defineComponent } from '@vue/composition-api';
import axiosIns from '@/libs/axios';
import AcenteFinansEkle from './AcenteFinansEkle.vue';
export default defineComponent({
  components: { VueGoodTable, AcenteFinansEkle },
  setup(props, context) {
    const expo = {};
    const { route } = useRouter();
    const toast = useToast();
    expo.kurTurleri = computed(() => store.getters.getKurAyarlari);

    expo.openModalFinans = ref(false);
    expo.updateData = ref(null);

    expo.serverParams = ref({
      ac_k_no: null,
      page: 1,
      page_size: 20,
    });

    expo.columns = ref([
      {
        label: 'Tarih',
        field: 'tarih',
        width: '10%',
      },
      {
        label: 'Ürün Bilgisi',
        field: 'urun_adi',
      },
      {
        label: 'G/C',
        field: 'gc_kodu',
        width: '10%',
      },
      {
        label: 'Tutar',
        field: 'tutar',
        width: '15%',
        thClass: 'text-right',
        tdClass: 'text-right',
      },
      {
        label: 'Statü',
        field: 'statu',
        width: '10%',
        thClass: 'text-right',
        tdClass: 'text-right',
      },
      {
        label: 'İşlemler',
        field: 'actions',
        thClass: 'text-right',
        tdClass: 'text-right',
        width: '8%',
      },
    ]);
    expo.rows = computed(() => store.getters.getAcenteHareketler);
    expo.toplam = ref({
      beklemede: 0,
      iptal: 0,
      tamamlandi: 0,
      odeme: 0,
      kalan: 0,
    });

    expo.isLoading = ref(false);
    expo.count = ref(0);

    expo.onSearchFilter = () => {};
    expo.onPageChange = () => {};
    expo.onPerPageChange = () => {};

    const handlerFetchAllData = async () => {
      context.emit('show', true);
      context.emit('title', 'Acente Finans');
      const k_no = route.value.params.k_no;
      if (k_no) {
        expo.serverParams.value.ac_k_no = k_no;
        store.dispatch('acenteHareketListele', expo.serverParams.value).then((res) => {
          if (res.data.success) {
            if (res.data.toplam != undefined) {
              expo.toplam.value = res.data.toplam;
            }
            context.emit('show', false);
          }
        });
      }
    };

    handlerFetchAllData();

    expo.handlerRemove = (payload) => {
      Swal.fire({
        title: 'Uyarı',
        text: 'Kayit Silinecektir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          store.dispatch('acenteHareketSil', payload).then((res) => {
            if (res.data.success === true) {
              toast.error('Silme Başarılı', { position: 'bottom-left' });
              // let index = expo.rows.value.findIndex((x) => x.k_no == payload.k_no);
              // expo.rows.value.splice(index, 1);

              expo.toplam.value = { ...expo.toplam.value, ...res.data.toplam };
            }
          });
        }
      });
    };

    expo.handlerUpdate = (payload) => {
      expo.updateData.value = payload;
      expo.openModalFinans.value = true;
    };

    return { ...expo };
  },
});
</script>

<style lang="scss"></style>
