<template>
  <div>
    <vue-good-table
      mode="remote"
      :columns="columns"
      :rows="rows"
      :line-numbers="true"
      :pagination-options="{
        enabled: true,
        perPage: 10,
        position: 'bottom',
        perPageDropdown: [5, 10, 20, 30, 50, 70, 100],
        dropdownAllowAll: true,
        firstLabel: 'İlk Sayfa',
        lastLabel: 'Son Sayfa',
        nextLabel: 'İleri',
        prevLabel: 'Geri',
        rowsPerPageLabel: 'Sayfa Başı Satır Sayısı',
        ofLabel: 've',
        pageLabel: 'Sayfa', // for 'pages' mode
        allLabel: 'Tümü',
      }"
      :search-options="{
        enabled: true,
        trigger: 'enter',
        placeholder: 'Tur Ara',
      }"
      @on-search="onSearchFilter"
      @on-page-change="onPageChange"
      @on-per-page-change="onPerPageChange"
      :isLoading="isLoading"
      :totalRows="count"
      :sort-options="{
        enabled: false,
      }"
      styleClass="vgt-table striped bordered"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'talep_no'">
          <router-link
            :to="{
              name: 'destek-hatti-detay',
              params: { k_no: props.row.k_no },
            }"
          >
            <b-badge variant="" class="d-block mt-1 rounded-0 text-left">
              {{ props.row.talep_no }}
            </b-badge>
          </router-link>
        </span>
        <span v-else-if="props.column.field === 'departman'">
          <router-link
            :to="{
              name: 'destek-hatti-detay',
              params: { k_no: props.row.k_no },
            }"
          >
            <span class="rounded-0 bg-warning p-1 text-white d-block text-left">
              {{ departmanText(props.row.departman) }}
            </span>
            <span class="rounded-0 p-1 text-white d-block text-left" :style="`background-color:${onemDereceColor(props.row.oncelik)}`">
              {{ onemDereceText(props.row.oncelik) }}
            </span>
          </router-link>
        </span>
        <span v-else-if="props.column.field === 'konu'">
          <router-link
            :to="{
              name: 'destek-hatti-detay',
              params: { k_no: props.row.k_no },
            }"
          >
            {{ props.row.konu }}
          </router-link>
        </span>
        <span v-else-if="props.column.field === 'statu'">
          <span
            class="rounded-0 p-1 text-white d-block text-center"
            :class="props.row.statu == 'yanit_bekliyor' ? 'bg-warning' : props.row.statu == 'cevaplandi' ? 'bg-success' : 'bg-danger'"
          >
            {{ statuText(props.row.statu) }}
          </span>
        </span>
        <span v-else-if="props.column.field === 'tarih'">
          <span class="rounded-0 bg-warning p-1 text-white d-block text-right pr-2">
            {{ props.row.save_date | momentText }}
          </span>
          <span class="rounded-0 bg-info p-1 text-white d-block text-right pr-2">
            {{ props.row.edit_date | momentText }}
          </span>
        </span>
        <span v-else-if="props.column.field === 'action'" class="float-right px-1">
          <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret menu-class="w-100">
            <template #button-content>
              <i class="fad fa-ellipsis-v-alt"></i>
            </template>
            <b-dropdown-item
              :to="{
                name: 'destek-hatti-detay',
                params: { k_no: props.row.k_no },
              }"
            >
              <i class="fad fa-eye" /> Detay
            </b-dropdown-item>

            <b-dropdown-item @click="handlerRemove(props.row.k_no)"> <i class="fad fa-trash" /> Sil</b-dropdown-item>
          </b-dropdown>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import store from '@/store';
import Swal from 'sweetalert2';
import vSelect from 'vue-select';
import { VueGoodTable } from 'vue-good-table';
import { useToast } from 'vue-toastification/composition';
import { useRouter } from '@/libs/utils';
import { computed, defineComponent, onMounted, ref, watch } from '@vue/composition-api';
import Pagination from '@/views/rezervasyonlar/component/listele/Pagination.vue';
export default defineComponent({
  components: {
    vSelect,
    VueGoodTable,
    Pagination,
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();
    const { route, router } = useRouter();
    expo.columns = ref([
      {
        label: 'Talep NO',
        field: 'talep_no',
        width: '15%',
      },
      {
        label: 'Departman',
        field: 'departman',
        width: '15%',
      },
      {
        label: 'Konu',
        field: 'konu',
      },
      {
        label: 'Statü',
        field: 'statu',
        width: '12%',
        tdClass: 'text-right',
      },
      {
        label: 'Oluşturma Güncelleme Tarihi',
        field: 'tarih',
        width: '25%',
        tdClass: 'text-right',
      },
      {
        field: 'action',
        width: '3%',
      },
    ]);
    expo.rows = ref([]);
    expo.count = ref(0);

    expo.isLoading = ref(false);
    const serverParams = ref({
      ac_k_no: route.value.params.k_no,
      konu: null,
      page: 1,
      page_size: 10,
    });

    expo.departmanText = computed(() => {
      const statusText = {
        satis: 'Satış',
        muhasebe: 'Muhasebe',
        teknik_destek: 'Teknik Destek',
        oneri_sikayet: 'Öneri Şikayet',
        diger: 'Diğer',
      };
      return (status) => statusText[status];
    });

    expo.onemDereceText = computed(() => {
      const statusText = {
        dusuk: 'Düşük',
        normal: 'Normal',
        acil: 'Acil',
        cok_acil: 'Çok Acil',
      };
      return (status) => statusText[status];
    });

    expo.onemDereceColor = computed(() => {
      const statusText = {
        dusuk: '#03C200',
        normal: '#B07E00',
        acil: '#E85100',
        cok_acil: '#FF0000',
      };
      return (status) => statusText[status];
    });

    expo.statuText = computed(() => {
      const statusText = {
        yanit_bekliyor: 'Yanıt Bekliyor',
        cevaplandi: 'Cevaplandı',
        kapandi: 'Kapandı',
      };
      return (status) => statusText[status];
    });

    expo.handlerFetchAllData = async () => {
      expo.isLoading.value = true;
      context.emit('show', true);
      context.emit('title', 'Destek Hatti');

      await store.dispatch('destekHattiListele', serverParams.value).then((res) => {
        if (res.data.success) {
          expo.count.value = res.data.count;
          expo.rows.value = res.data.data;
        }
        context.emit('show', false);
        expo.isLoading.value = false;
      });
    };

    expo.handlerFetchAllData();

    expo.onSearchFilter = (event) => {
      const { searchTerm } = event;
      serverParams.value.konu = searchTerm;
      expo.handlerFetchAllData();
    };

    expo.onPageChange = (event) => {
      serverParams.value.page = event.currentPage;
      expo.handlerFetchAllData();
    };

    expo.onPerPageChange = (event) => {
      serverParams.value.page_size = event.currentPerPage;
      expo.handlerFetchAllData();
    };

    return { ...expo };
  },
});
</script>

<style scoped></style>
