<template>
  <div>
    <vue-good-table
      mode="remote"
      :columns="columns"
      :rows="rows"
      :line-numbers="true"
      :pagination-options="{
        enabled: true,
        perPage: 10,
        position: 'bottom',
        perPageDropdown: [5, 10, 20, 30, 50, 70, 100],
        dropdownAllowAll: true,
        firstLabel: 'İlk Sayfa',
        lastLabel: 'Son Sayfa',
        nextLabel: 'İleri',
        prevLabel: 'Geri',
        rowsPerPageLabel: 'Sayfa Başı Satır Sayısı',
        ofLabel: 've',
        pageLabel: 'Sayfa', // for 'pages' mode
        allLabel: 'Tümü',
      }"
      :search-options="{
        enabled: true,
        trigger: 'enter',
        placeholder: 'Tur Ara',
      }"
      @on-search="onSearchFilter"
      @on-page-change="onPageChange"
      @on-per-page-change="onPerPageChange"
      :isLoading="isLoading"
      :totalRows="count"
      :sort-options="{
        enabled: false,
      }"
      styleClass="vgt-table striped bordered"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'urun_tipi'">
          <router-link
            :to="{
              name: 'rezervasyon-detay',
              params: { k_no: props.row.k_no },
            }"
          >
            <b-badge variant="" class="d-block mt-1 rounded-0">
              {{ urunTipi(props.row.urun_tipi) }}
            </b-badge>
          </router-link>
        </span>
        <span v-else-if="props.column.field === 'islem_tarihi'">
          <router-link
            :to="{
              name: 'rezervasyon-detay',
              params: { k_no: props.row.k_no },
            }"
          >
            <b-badge variant="" class="d-block rounded-0 font-weight-light" style="font-size: 12px">
              {{ props.row.islem_tarihi | momentFull }}</b-badge
            >
          </router-link>
        </span>
        <span v-else-if="props.column.field === 'urun_adi'">
          <router-link
            :to="{
              name: 'rezervasyon-detay',
              params: { k_no: props.row.k_no },
            }"
          >
            <b-badge class="d-block rounded-0 font-weight-light text-left" style="font-size: 12px"> Rez No :{{ props.row.k_no }} </b-badge>
            <template v-if="props.row.urun_tipi == 'villa' || props.row.urun_tipi == 'yat'">
              <b-badge
                v-for="(urun, index) in props.row.urunler"
                :key="index"
                variant="primary"
                class="d-block mt-1 rounded-0 text-left"
                style="font-size: 12px"
              >
                {{ urun.baslik['tr'] }}
              </b-badge>
            </template>
            <template v-else>
              <template v-if="props.row.sepet_urunler.length > 1">
                <b-badge variant="warning" class="rounded-0 font-weight-light" style="font-size: 13px">
                  +{{ props.row.sepet_urunler.length }} Ürün İçeriyor
                </b-badge>
              </template>
              <template v-else>
                <b-badge
                  v-for="(urun, index) in props.row.sepet_urunler"
                  :key="index"
                  variant="primary"
                  class="d-block mt-1 rounded-0 text-left rounded-0 font-weight-light"
                  style="font-size: 12px"
                  v-b-tooltip.hover.top="urun.baslik['tr']"
                >
                  {{ urun.baslik['tr'] }}
                </b-badge>
              </template>
            </template>
            <template v-if="props.row.ac_k_no">
              <b-badge variant="info" class="d-block mt-1 rounded-0 text-left" style="font-size: 12px">
                {{ props.row.acente_firma_adi }} - {{ props.row.acente_temsilci }}
              </b-badge>
            </template>
          </router-link>
        </span>
        <span v-else-if="props.column.field === 'fiyat'">
          <template v-if="props.row.sabit_kur == props.row.secilen_kur">
            <b-badge variant="warning" class="rounded-0 font-weight-light" style="font-size: 12px">
              Toplam Tutar :
              {{ props.row.rezervasyon_toplam.genel_toplam | cur }}
              {{ props.row.secilen_kur }}
            </b-badge>
            <b-badge
              variant="info"
              class="rounded-0 font-weight-light"
              style="font-size: 12px"
              v-if="props.row.rezervasyon_toplam.on_odeme_toplam"
            >
              Ön Ödeme :
              {{ props.row.rezervasyon_toplam.on_odeme_toplam | cur }}
              {{ props.row.secilen_kur }}
            </b-badge>
            <b-badge variant="info" class="rounded-0 font-weight-light" style="font-size: 12px">
              Tahsilat : {{ props.row.tahsilat | cur }}
              {{ props.row.secilen_kur }}
            </b-badge>

            <b-badge
              :variant="kalanBakiye(props.row.rezervasyon_toplam.genel_toplam, props.row.tahsilat) > 0 ? 'danger' : 'success'"
              class="rounded-0 font-weight-light"
              style="font-size: 12px"
            >
              Bakiye :
              {{ kalanBakiye(props.row.rezervasyon_toplam.genel_toplam, props.row.tahsilat) | cur }}
              {{ props.row.secilen_kur }}
            </b-badge>
          </template>
          <template v-else>
            <b-badge variant="warning" class="rounded-0 font-weight-light" style="font-size: 12px">
              Toplam Tutar :
              {{ props.row.kur_rezervasyon_toplam.ara_toplam | cur }}
              {{ props.row.secilen_kur }}
            </b-badge>
            <b-badge
              variant="info"
              class="rounded-0 font-weight-light"
              style="font-size: 12px"
              v-if="props.row.kur_rezervasyon_toplam.on_odeme_toplam"
            >
              Ön Ödeme :
              {{ props.row.kur_rezervasyon_toplam.on_odeme_toplam | cur }}
              {{ props.row.secilen_kur }}
            </b-badge>
            <b-badge variant="info" class="rounded-0 font-weight-light" style="font-size: 12px">
              Tahsilat : {{ props.row.tahsilat | cur }}
              {{ props.row.secilen_kur }}
            </b-badge>

            <b-badge
              :variant="kalanBakiye(props.row.kur_rezervasyon_toplam.genel_toplam, props.row.tahsilat) > 0 ? 'danger' : 'success'"
              class="rounded-0 font-weight-light"
              style="font-size: 12px"
            >
              Bakiye :
              {{ kalanBakiye(props.row.kur_rezervasyon_toplam.genel_toplam, props.row.tahsilat) | cur }}
              {{ props.row.secilen_kur }}
            </b-badge>
          </template>
        </span>
        <span v-else-if="props.column.field === 'odeme'">
          <b-badge variant="success" class="rounded-0 font-weight-light" style="font-size: 12px">
            {{
              props.row.odeme_yontemi == 'kredi_karti' ? 'Kredi Kartı' : props.row.odeme_yontemi == 'havale_eft' ? 'Havale' : 'Kapıda Ödeme'
            }}
          </b-badge>
          <br />
          <b-badge
            :variant="props.row.odeme_tipi == 'on_odeme' ? 'warning' : 'info'"
            class="rounded-0 font-weight-light"
            style="font-size: 12px"
          >
            {{ props.row.odeme_tipi == 'on_odeme' ? 'Ön Ödemeli' : 'Tamamı' }}
          </b-badge>
          <b-badge
            :variant="props.row.tahsilat_durum == 1 ? 'info' : props.row.tahsilat_durum == 2 ? 'success' : 'danger'"
            class="rounded-0 font-weight-light"
            style="font-size: 12px"
          >
            {{ props.row.tahsilat_durum | tahsilatDurum }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'statu'">
          <b-badge class="rounded-0 font-weight-light" style="font-size: 12px" :style="{ background: props.row.rezervasyon_drm_renk }">
            {{ props.row.rezervasyon_drm['tr'].baslik }}
          </b-badge>
          <b-badge
            :variant="props.row.rezervasyon_kapama == 'kapandi' ? 'danger' : props.row.rezervasyon_kapama == 'aktif' ? 'success' : 'danger'"
            class="rounded-0 font-weight-light"
            style="font-size: 12px"
          >
            {{ props.row.rezervasyon_kapama | rezKapama }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'action'" class="float-right px-1">
          <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret menu-class="w-100">
            <template #button-content>
              <i class="fad fa-ellipsis-v-alt"></i>
            </template>
            <b-dropdown-item
              :to="{
                name: 'rezervasyon-detay',
                params: { k_no: props.row.k_no },
              }"
            >
              <i class="fad fa-eye" /> Detay</b-dropdown-item
            >
            <b-dropdown-item
              :to="{
                name: 'rezervasyon-guncelle',
                params: { k_no: props.row.k_no },
              }"
            >
              <i class="fad fa-edit" /> Güncelle</b-dropdown-item
            >
            <b-dropdown-item
              :to="{
                name: 'rezervasyon-fisi',
                params: { k_no: props.row.k_no },
              }"
            >
              <i class="fad fa-edit" /> Yazdır</b-dropdown-item
            >
            <b-dropdown-item v-if="props.row.rezervasyon_kapama == 'aktif'" @click="handlerRemove(props.row.k_no)">
              <i class="fad fa-trash" /> Sil
            </b-dropdown-item>
          </b-dropdown>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import store from '@/store';
import Swal from 'sweetalert2';
import vSelect from 'vue-select';
import { VueGoodTable } from 'vue-good-table';
import { useToast } from 'vue-toastification/composition';
import { useRouter } from '@/libs/utils';
import { computed, defineComponent, onMounted, ref, watch } from '@vue/composition-api';
import Pagination from '@/views/rezervasyonlar/component/listele/Pagination.vue';
export default defineComponent({
  components: {
    vSelect,
    VueGoodTable,
    Pagination,
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();
    const { route, router } = useRouter();
    expo.columns = ref([
      {
        label: 'İşlem Tarihi',
        field: 'islem_tarihi',
        width: '15%',
      },
      {
        label: 'Ürün Adı',
        field: 'urun_adi',
      },
      {
        label: 'Fiyat',
        field: 'fiyat',
        width: '15%',
        tdClass: 'text-right',
      },
      {
        label: 'Ödeme',
        field: 'odeme',
        width: '12%',
        tdClass: 'text-right',
      },
      {
        label: 'Statü',
        field: 'statu',
        width: '20%',
        tdClass: 'text-right',
      },
      {
        field: 'action',
        width: '3%',
      },
    ]);
    expo.rows = ref([]);
    expo.count = ref(0);

    expo.isLoading = ref(false);
    const serverParams = ref({
      ac_k_no: null,
      page: 1,
      page_size: 5,
    });

    expo.handlerFetchAllData = async () => {
      expo.isLoading.value = true;
      context.emit('show', true);
      context.emit('title', 'Acente Rezervasyonlar');
      const k_no = route.value.params.k_no;
      console.log(k_no);
      if (k_no) {
        serverParams.value.ac_k_no = k_no;
        store.dispatch('acenteRezervasyonListele', serverParams.value).then((res) => {
          if (res.data.success) {
            expo.rows.value = res.data.data;
            expo.count.value = res.data.count;
            context.emit('show', false);
            expo.isLoading.value = false;
          }
        });
      }
    };

    expo.handlerFetchAllData();

    expo.onSearchFilter = (event) => {
      const { searchTerm } = event;
      serverParams.value.acente_adi = searchTerm;
      expo.handlerFetchAllData();
    };

    expo.onPageChange = (event) => {
      serverParams.value.page = event.currentPage;
      expo.handlerFetchAllData();
    };

    expo.onPerPageChange = (event) => {
      serverParams.value.page_size = event.currentPerPage;
      expo.handlerFetchAllData();
    };

    expo.kalanBakiye = computed(() => {
      return (genel_toplam, tahsil) => {
        let toplam = 0;
        toplam = genel_toplam - tahsil;
        return Math.floor(toplam);
      };
    });

    return { ...expo };
  },
});
</script>

<style scoped></style>
