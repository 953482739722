var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vue-good-table',{attrs:{"mode":"remote","columns":_vm.columns,"rows":_vm.rows,"line-numbers":true,"pagination-options":{
      enabled: true,
      perPage: 10,
      position: 'bottom',
      perPageDropdown: [5, 10, 20, 30, 50, 70, 100],
      dropdownAllowAll: true,
      firstLabel: 'İlk Sayfa',
      lastLabel: 'Son Sayfa',
      nextLabel: 'İleri',
      prevLabel: 'Geri',
      rowsPerPageLabel: 'Sayfa Başı Satır Sayısı',
      ofLabel: 've',
      pageLabel: 'Sayfa', // for 'pages' mode
      allLabel: 'Tümü',
    },"search-options":{
      enabled: true,
      trigger: 'enter',
      placeholder: 'Tur Ara',
    },"isLoading":_vm.isLoading,"totalRows":_vm.count,"sort-options":{
      enabled: false,
    },"styleClass":"vgt-table striped bordered"},on:{"on-search":_vm.onSearchFilter,"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'talep_no')?_c('span',[_c('router-link',{attrs:{"to":{
            name: 'destek-hatti-detay',
            params: { k_no: props.row.k_no },
          }}},[_c('b-badge',{staticClass:"d-block mt-1 rounded-0 text-left",attrs:{"variant":""}},[_vm._v(" "+_vm._s(props.row.talep_no)+" ")])],1)],1):(props.column.field === 'departman')?_c('span',[_c('router-link',{attrs:{"to":{
            name: 'destek-hatti-detay',
            params: { k_no: props.row.k_no },
          }}},[_c('span',{staticClass:"rounded-0 bg-warning p-1 text-white d-block text-left"},[_vm._v(" "+_vm._s(_vm.departmanText(props.row.departman))+" ")]),_c('span',{staticClass:"rounded-0 p-1 text-white d-block text-left",style:(("background-color:" + (_vm.onemDereceColor(props.row.oncelik))))},[_vm._v(" "+_vm._s(_vm.onemDereceText(props.row.oncelik))+" ")])])],1):(props.column.field === 'konu')?_c('span',[_c('router-link',{attrs:{"to":{
            name: 'destek-hatti-detay',
            params: { k_no: props.row.k_no },
          }}},[_vm._v(" "+_vm._s(props.row.konu)+" ")])],1):(props.column.field === 'statu')?_c('span',[_c('span',{staticClass:"rounded-0 p-1 text-white d-block text-center",class:props.row.statu == 'yanit_bekliyor' ? 'bg-warning' : props.row.statu == 'cevaplandi' ? 'bg-success' : 'bg-danger'},[_vm._v(" "+_vm._s(_vm.statuText(props.row.statu))+" ")])]):(props.column.field === 'tarih')?_c('span',[_c('span',{staticClass:"rounded-0 bg-warning p-1 text-white d-block text-right pr-2"},[_vm._v(" "+_vm._s(_vm._f("momentText")(props.row.save_date))+" ")]),_c('span',{staticClass:"rounded-0 bg-info p-1 text-white d-block text-right pr-2"},[_vm._v(" "+_vm._s(_vm._f("momentText")(props.row.edit_date))+" ")])]):(props.column.field === 'action')?_c('span',{staticClass:"float-right px-1"},[_c('b-dropdown',{attrs:{"size":"lg","variant":"link","toggle-class":"text-decoration-none","no-caret":"","menu-class":"w-100"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('i',{staticClass:"fad fa-ellipsis-v-alt"})]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"to":{
              name: 'destek-hatti-detay',
              params: { k_no: props.row.k_no },
            }}},[_c('i',{staticClass:"fad fa-eye"}),_vm._v(" Detay ")]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.handlerRemove(props.row.k_no)}}},[_c('i',{staticClass:"fad fa-trash"}),_vm._v(" Sil")])],1)],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }