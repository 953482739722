<template>
  <b-sidebar
    backdrop
    shadow
    :visible="openModal"
    right
    width="50%"
    @change="(val) => $emit('closeModal', val)"
    id="sidebar-right"
    title="Sidebar"
  >
    <template #header="{ hide }">
      <div class="sidebar-header">
        <i class="fad fa-times-square" @click="hide" />
        <h5 class="mb-0 font-weight-bold">
          <i class="fad fa-users-class"></i> Acente Ayarlar
        </h5>
      </div>
    </template>
    <template #default>
      <b-overlay :show="loaded">
        <div class="sidebar-body">
          <validation-observer
            ref="observer"
            v-slot="{ handleSubmit }"
            class=""
          >
            <b-form
              @submit.stop.prevent="handleSubmit(onSubmit)"
              @reset.prevent="resetForm"
              autocomplete="off"
            >
              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group label="Ana Acente">
                    <v-select
                      v-model="form.hakedis_isleme_durum"
                      :options="hakedisDurumlar"
                      :reduce="(kur) => kur.value"
                      label="title"
                      :clearable="true"
                      class="select-size-lg"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- <b-row>
                <b-col cols="12" md="6">
                  <validation-provider
                    name="Firma Adı"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <b-form-group label="Firma Adı">
                      <b-form-input
                        size="lg"
                        class="rounded-0"
                        v-model="form.firma_adi"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-1-live-feedback"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="6">
                  <div
                    class="d-flex justify-content-between align-items-center py-1"
                  >
                    <label class="font-weight-bold h6 mt-2"
                      >Kullanım Durumu</label
                    >
                    <b-form-checkbox
                      :checked="form.statu"
                      v-model="form.statu"
                      switch
                      size="lg"
                    />
                  </div>
                </b-col>
              </b-row> -->
              <b-row>
                <b-col cols="12" md="6" lg="6">
                  <div class="d-flex">
                    <b-button
                      squared
                      block
                      type="submit"
                      size="lg"
                      variant="success"
                      class="mr-2"
                    >
                      <i class="fad fa-save pr-2"></i>
                      <span class="align-middle">KAYDET</span>
                    </b-button>
                    <b-button
                      squared
                      size="lg"
                      variant="danger"
                      @click="handlerRemove(item.k_no)"
                    >
                      <i class="fad fa-trash"></i>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </div>
      </b-overlay>
    </template>
  </b-sidebar>
</template>

<script>
import vSelect from "vue-select";
import {
  ref,
  toRefs,
  computed,
  defineComponent,
  watch,
} from "@vue/composition-api";
import store from "@/store";
export default defineComponent({
  components: { vSelect },
  props: {
    openModal: [Boolean],
    data: { type: Object, required: true },
  },
  setup(props) {
    const expo = {};
    const { data } = toRefs(props);
    expo.loaded = ref(false);

    expo.acenteler = computed(() => store.getters.getAcenteler);

    expo.form = ref({
      ac_k_no: data.value.k_no,
      hakedis_isleme_durum: null, //hemen kapandıktan sonra
      komisyon: {
        villa: {
          turu: "yuzde",
          oran: 0,
        },
        yat: {
          turu: "yuzde",
          oran: 0,
        },
        tur: {
          turu: "yuzde",
          oran: 0,
        },
        transfer: {
          turu: "yuzde",
          oran: 0,
        },
        bilet: {
          turu: "yuzde",
          oran: 0,
        },
        arac_kira: {
          turu: "yuzde",
          oran: 0,
        },
      },
      makisimum_alt_uye: 1,
    });

    expo.hakedisDurumlar = ref([
      {
        value: "hemen",
        title: "Rezervasyon Yapıldıgında",
      },
      {
        value: "kapanis",
        title: "Rezervasyon Kapatıldıgında",
      },
    ]);

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = async () => {
      expo.loaded.value = true;
      const baseUrl = expo.form.value.k_no ? "acenteGuncelle" : "acenteEkle";
      await store.dispatch(baseUrl, expo.form.value).then((res) => {
        if (res.data.success) {
          expo.loaded.value = false;
        }
      });
    };

    // watch(
    //   updateData,
    //   (val) => {
    //     console.log(val);
    //     expo.form.value = { ...val };
    //   },
    //   { deep: true }
    // );

    return { ...expo };
  },
});
</script>

<style lang="scss">
.b-sidebar {
  .b-sidebar-body {
    overflow: hidden;
    overflow-y: auto;
  }
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 0px;
  border-bottom: 1px solid #ebe9f1;
  h5 {
    color: #6e6b7b;
  }
  i {
    font-size: 1.5rem;
  }
}

.sidebar-body {
  padding: 0 10px 10px 10px;
}
</style>
