<template>
  <div>
    <b-card no-body class="m-3 rounded-0">
      <b-card-header class="bg-white">
        <div class="d-flex justify-content-between align-items-center">
          <strong> <i class="fad fa-money-bill"></i> Sabit Komisyon Ayarları </strong>
          <b-button squared size="sm" variant="success" class="mr-2" @click="$emit('submit', true)">
            <i class="fad fa-save"></i> Tüm Değişiklikleri Kaydet
          </b-button>
        </div>
      </b-card-header>
      <b-row>
        <b-col cols="12" md="7">
          <b-form-group label-cols="5" label="Ürün Satış Komisyon">
            <b-input-group>
              <b-input-group-append>
                <v-select
                  v-model="form.sabit.urun.turu"
                  :options="komisyonTuru"
                  :reduce="(kdv) => kdv.value"
                  label="title"
                  :clearable="false"
                  class="invoice-filter-select d-block select-size-lg"
                  style="min-width: 150px"
                />
              </b-input-group-append>
              <b-form-input
                size="lg"
                class="rounded-0 text-right"
                ref="baslik"
                :disabled="form.sabit.urun.turu == 'kapali'"
                v-model="form.sabit.urun.oran"
              />
              <b-input-group-prepend is-text style="">
                <span class="px-2" style="font-size: 16px">
                  {{ form.sabit.urun.turu == 'yuzde' ? '%' : kur.varsayilan }}
                </span>
              </b-input-group-prepend>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="7">
          <b-form-group label-cols="5" label="Ekstra Ürün Satış Komisyon">
            <b-input-group>
              <b-input-group-append>
                <v-select
                  v-model="form.sabit.ekstra.turu"
                  :options="komisyonTuru"
                  :reduce="(kdv) => kdv.value"
                  label="title"
                  :clearable="false"
                  class="invoice-filter-select d-block select-size-lg"
                  style="min-width: 150px"
                />
              </b-input-group-append>
              <b-form-input
                size="lg"
                class="rounded-0 text-right"
                ref="baslik"
                :disabled="form.sabit.ekstra.turu == 'kapali'"
                v-model="form.sabit.ekstra.oran"
              />
              <b-input-group-prepend is-text style="">
                <span class="px-2" style="font-size: 16px">
                  {{ form.sabit.ekstra.turu == 'yuzde' ? '%' : kur.varsayilan }}
                </span>
              </b-input-group-prepend>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>

    <b-card no-body class="m-3 rounded-0 h-100" v-if="whatIsType(type)">
      <b-card-header class="bg-white">
        <div class="d-flex justify-content-between align-items-center">
          <strong> <i class="fad fa-money-bill"></i> Ürün Bazlı Komisyon Ayarları </strong>
          <div class="d-flex">
            <b-button squared size="sm" variant="info" class="mr-2" @click="openModal = true">
              <i class="fad fa-plus"></i>
            </b-button>
            <b-button squared size="sm" variant="success" @click="$emit('submit', true)">
              <i class="fad fa-save"></i> Tüm Değişiklikleri Kaydet
            </b-button>
          </div>
        </div>
      </b-card-header>
      <b-card-text>
        <b-alert class="rounded-0 text-center mb-0" show v-if="!form.urun_komisyon.length" variant="info">
          <h4>Bilgi</h4>
          <p>Şuan ürün bazlı komisyon bulunmamaktadır.</p>
        </b-alert>

        <vue-good-table
          v-else
          :columns="columns"
          :rows="form.urun_komisyon"
          :line-numbers="false"
          :pagination-options="{
            enabled: false,
            perPage: 100,
            mode: 'pages',
          }"
          @on-selected-rows-change="selectionChanged"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true,
            selectionText: 'Satır Secildi.',
            clearSelectionText: 'Temizle',
          }"
          :search-options="{ enabled: true }"
          styleClass="vgt-table striped bordered"
        >
          <div slot="selected-row-actions">
            <b-button squared variant="danger" v-b-tooltip.hover="'Toplu İşlem'" @click="handlerRemoveSelected">
              <i class="fad fa-trash" /> Seçili Satırları Sil
            </b-button>
          </div>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'baslik'">
              <span class="font-weight-bold d-block text-dark">
                {{ urunTitle(props.row.k_no) }}
              </span>
            </span>
            <span v-else-if="props.column.field === 'komisyon'">
              <b-input-group>
                <b-input-group-append>
                  <v-select
                    v-model="form.urun_komisyon[props.row.originalIndex].komisyon.turu"
                    :options="komisyonTuru"
                    :reduce="(kdv) => kdv.value"
                    label="title"
                    :clearable="false"
                    class="invoice-filter-select select-size-sm d-block"
                    style="min-width: 150px"
                  />
                </b-input-group-append>
                <b-form-input
                  class="rounded-0 text-right"
                  ref="baslik"
                  size="sm"
                  v-model="form.urun_komisyon[props.row.originalIndex].komisyon.oran"
                />
                <b-input-group-prepend is-text>
                  <span class="px-2" style="min-width: 40px; font-size: 11px">
                    {{ form.urun_komisyon[props.row.originalIndex].komisyon.turu == 'yuzde' ? '%' : kur.varsayilan }}
                  </span>
                </b-input-group-prepend>
              </b-input-group>
            </span>
            <span v-else-if="props.column.field === 'action'">
              <b-button squared variant="danger" @click="handlerRemoveItem(props.row.originalIndex)">
                <i class="fad fa-trash"></i
              ></b-button>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </b-card-text>
    </b-card>
    <KomisyonUrunAta :openModal="openModal" @closeModal="openModal = $event" :form="form" />
  </div>
</template>

<script>
import store from '@/store';
import vSelect from 'vue-select';
import Swal from 'sweetalert2';
import { useToast } from 'vue-toastification/composition';
import { VueGoodTable } from 'vue-good-table';
import KomisyonUrunAta from './KomisyonUrunAta.vue';
import { ref, toRefs, computed, defineComponent, watch } from '@vue/composition-api';
export default defineComponent({
  components: { vSelect, KomisyonUrunAta, VueGoodTable },
  props: {
    form: {
      type: Object,
      required: true,
    },
    kur: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const expo = {};
    expo.openModal = ref(false);

    const { form } = toRefs(props);

    const toast = useToast();
    const urunler = computed(() => store.getters.getUrunler);

    expo.multiSelectRow = ref([]);

    expo.whatIsType = computed(() => {
      return (val) => {
        if (val == 'villa' || val == 'yat' || val == 'tur' || val == 'emlak') return true;
        return false;
      };
    });

    expo.urunTitle = computed(() => {
      return (val) => {
        const urun = urunler.value.find((x) => x.k_no == val);

        if (urun != undefined) {
          return urun.baslik;
        } else {
          return '';
        }
      };
    });

    expo.komisyonTuru = ref([
      {
        value: 'kapali',
        title: 'Kapalı',
      },
      {
        value: 'yuzde',
        title: 'Yüzde',
      },
      {
        value: 'fiyat',
        title: 'Fiyat',
      },
    ]);

    expo.columns = ref([
      {
        label: 'Ürün Bilgisi',
        field: 'baslik',
        tdClass: 'align-middle',
        sortable: true,
      },
      {
        label: 'Komisyon',
        field: 'komisyon',
        width: '35%',
        tdClass: 'align-middle',
        sortable: false,
      },
      {
        label: 'İşlem',
        field: 'action',
        thClass: 'align-middle text-right',
        tdClass: 'align-middle text-right',
        sortable: false,
      },
    ]);

    expo.selectionChanged = (event) => {
      expo.multiSelectRow.value = [];
      event.selectedRows.forEach((el, index) => {
        expo.multiSelectRow.value.push({
          k_no: el.k_no,
        });
      });
    };

    expo.handlerRemoveSelected = () => {
      Swal.fire({
        title: 'Uyarı',
        text: 'Secili Kayitlar Silinecektir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          expo.multiSelectRow.value.forEach((el) => {
            const index = form.value.urun_komisyon.findIndex((x) => x.k_no == el.k_no);
            form.value.urun_komisyon.splice(index, 1);
          });
          toast.error('Ürün Silindi.', { position: 'bottom-left' });
        }
      });
    };

    expo.handlerRemoveItem = (index) => {
      Swal.fire({
        title: 'Uyarı',
        text: 'Kayit Silinecektir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet',
        cancelButtonText: 'Hayır',
      }).then((result) => {
        if (result.isConfirmed == true) {
          form.value.urun_komisyon.splice(index, 1);
          toast.error('Ürün Silindi.', { position: 'bottom-left' });
        }
      });
    };

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    return { ...expo };
  },
});
</script>

<style scoped></style>
